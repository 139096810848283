<template>
  <div class="cc releasenotesandfeatures">
    <div class="cc description">
      <p v-html="release.note"></p>
      <p>Enjoy, take care and keep going!</p>
    </div>

    <h3 v-if="featureHeading" class="bs display-4">Features</h3>
    <div v-if="release.features">
      <ul class="bs list-inline" aria-label="Features in this release">
        <li v-for="feature in release.features" :key="feature.name" class="bs list-inline-item">
          <div class="bs d-flex align-items-center">
            <span class="cc itemicon bs small"><fa icon="check-square" class="bs d-block"></fa></span>
            <h5>{{ feature.name }}</h5>
          </div>
          <p>{{ feature.description }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReleaseNotesAndFeatures",
  props: {
    release: Object,
    featureHeading: Boolean
  },
};
</script>

<style lang="scss">
.cc.releasenotesandfeatures {
  margin-top: 20px;

  .display-4 {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .list-inline-item {
    margin-top: 20px;
  }

  .itemicon {
    padding: 4px;
    margin: 0px 8px 8px 0px;
  }
}
</style>
