<template>
  <PageFrame>
    <PageTitle
      title="Digital Product Advisory."
      subtitle="Elevate your digital product engineering to world-class level."
    />
  </PageFrame>

  <PageContextBar title="Digital Product Advisory" pricetag="€ 3.000,- / day" icon="code" icon-color="lilac" sticky-on-desktop :items="tableofcontents" />

  <PageFrame background="widestickerbomb2">
    <VisualCue color="lilac" title="Let's Connect Worlds." image="mobile-shelf" image-right>
      <template v-slot:text>
        Every digital product is unique in form, function and identity, which in turn always requires a unique way to create, engineer and operate it.
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame no-spacing>
    <h2 class="display-4">
      We tailor our advisory to your uniqueness
    </h2>

    <p>
      Todays society exist in two worlds: The analog world and the digital world. We live and laugh in our analog, real world, yet more so communicate and interact in our digital, online world.
    </p>

    <p>
      Individualisation is very much a key success factor of any digital product development. As todays digital products require to be immensly adaptable and close to the customer, the creativity and process of developing the product needs to be uniquely individual as well.
    </p>

    <h2 class="display-4">
      Constructive creativity
    </h2>

    <p>
      In case you might be wondering what the CC abbrevation of our LABS is meant for - yes, it also means constructive creativity.
    </p>

    <p>
      We understand to translate the philosophy and mindset of compassionate collaboration into a collective consciuousness towards creating a product altogether with constructive Creativity.
    </p>

    <p>
      Our deep and practical knowledge of a wide toolbox of approaches to modern software and product development enables us to aim for collectively beneficial goals and navigators. For your unique situation and characteristics of your product creation process.
    </p>
  </PageFrame>

  <PagePoster
    title="Agile Product Development"
    description="While most modern digital products focus on fast development and learning, the heart and soul of Agility is often ignored: building the product with the customer while innovating with designers &amp; engineers."
    color="lilac"
  >
    <template v-slot:poster>
      Build<br />
      Your Product<br />
      With Customers.
    </template>
  </PagePoster>

  <PageFrame>
    <p>
      Our advisory is constantly and continuously on the cutting edge of best practices - in both engineering and management. Our ethical values require full dedication and discretion in any of our advisory assignments.
    </p>

    <PriceCard title="Digital Product Advisory" companyprice="€ 3.000,-" personprice="€ 2.000,-" />

    <ContactAction
      teaser="Let's design your digital product with your unique spirit."
      subtext="Connecting analog and digital worlds for great good."
      cta="Let's connect worlds"
    />
  </PageFrame>

  <PageLabels title="Our areas of expertise in Digital Product Advisory" :labels="labels" />
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import PagePoster from "@/components/PagePoster.vue";
import PriceCard from "@/components/PriceCard.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactAction from "@/components/ContactAction.vue";
import PageLabels from "@/components/PageLabels.vue";

export default {
  name: "DigitalProductAdvisoryPage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    PagePoster,
    PriceCard,
    VisualCue,
    ContactAction,
    PageLabels
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "arrow-right",
          iconOnRight: true,
          title: "Modern Management",
          route: "modernmanagement"
        }
      ],
      labels: [
        { name: "Agile Development" },
        { name: "Scrum" },
        { name: "Lean Startup" },
        { name: "Cross-Functional Teams" },
        { name: "eXtreme Engineering" },
        { name: "Interaction Design & Architecture" },
        { name: "Design Thinking" },
        { name: "Continuous Delivery" },
        { name: "Event Storming" },
        { name: "Value Proposition Canvas" },
        { name: "Adaptive Product Roadmap" },
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
