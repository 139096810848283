import thecclabs from "./lib/cclabs";

import "bootstrap";

import { createApp } from "vue";
import VueMatomo from "vue-matomo";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { fab } from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab);
library.add(fas, faEnvelope);

thecclabs.withVue(createApp(App))
    .greetTheWorld()
    .use(router)
    .useTracker(VueMatomo, router)
    .component('fa', FontAwesomeIcon)
    .mount("#app");
