<template>
  <div class="cc navbeam"
    :class="name + ' ' + (fixed ? 'fixed-top' : (sticky ? 'sticky-top' : ''))"
    :style="`top:${offset};`"
  >
    <nav
      class="bs navbar navbar-light"
      :aria-label="name"
      :class="(expanded ? 'navbar-expand' : 'navbar-expand-lg') + (flip ? ' cc flip' : '')"
      :style="`--fin:${fin};`"
    >
      <div class="cc spacer bs flex-fill" :style="`--beam:${beam}; --bar:${bar};`"></div>

      <div class="bs container justify-content-between">
        <div class="bs navbar-brand mr-auto" :style="`--beam:${beam}; --bar:${bar};`">
          <slot name="brand"></slot>
        </div>

        <div class="cc spacer bs flex-fill" :style="`--beam:${beam}; --bar:${bar};`"></div>

        <button class="bs navbar-toggler" type="button" data-toggle="collapse" :data-target="`#${name}-nav-list`" :aria-controls="`${name}-nav-list`" aria-expanded="false" aria-label="Toggle navigation">
          <fa icon="bars"></fa>
        </button>

        <div class="bs collapse navbar-collapse" :id="`${name}-nav-list`">
          <ul class="bs navbar-nav">
            <li class="bs nav-item" v-for="item in items" :key="item.key" :style="`--beam:${beam}; --bar:${bar};`">
              <router-link :to="{ name: item.route }" class="bs nav-link text-nowrap" data-toggle="collapse" :data-target="`#${name}-nav-list.show`">
                <span :class="small ? 'small' : ''">{{ item.title }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="cc spacer bs flex-fill" :style="`--beam:${beam}; --bar:${bar};`"></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBeam",
  props: {
    name: String,
    items: Object,
    small: Boolean,
    fixed: Boolean,
    sticky: Boolean,
    flip: Boolean,
    expanded: Boolean,
    beam: {
      type: String,
      default: "8px"
    },
    bar: {
      type: String,
      default: "60px"
    },
    fin: {
      type: String,
      default: "0px"
    },
    offset: String
  }
};
</script>

<style lang="scss">

$cc-beamer-bg: $white;
$cc-beamer-bg-dark: $dark;

$cc-beamer-color: $dark;
$cc-beamer-color-dark: $gray-400;

$cc-beamer-color-hover: $black;
$cc-beamer-color-hover-dark: $white;

$cc-beamer-border-color: $light;
$cc-beamer-border-color-dark: $black;

$cc-beamer-beam-color: $gray-300;
$cc-beamer-beam-color-dark: $black;

$cc-beamer-beam-color-hover: $dark;
$cc-beamer-beam-color-hover-dark: $gray-400;

$cc-beamer-nav-spacer: 15px;

.cc.navbeam {
  background-color: $cc-beamer-bg;

  .navbar {
    padding: 0px;
    border-bottom: var(--fin) solid $cc-beamer-border-color;

    .container {
      padding: 0px;
    }

    .navbar-brand {
      padding-left: $cc-beamer-nav-spacer;
      padding-right: $cc-beamer-nav-spacer;
    }

    .navbar-collapse {
      flex-grow: 0;
    }

    .navbar-toggler {
      border: 0px;
      padding: 0px;
      margin-right: $cc-beamer-nav-spacer;
      color: $cc-beamer-color;
    }

    .nav-item, .navbar-brand {
      * { color: $cc-beamer-color; }
      border-top: var(--beam) solid $cc-beamer-beam-color;
      min-height: var(--bar);
      padding-top: var(--beam);
    }

    .nav-item:hover {
      * { color: $cc-beamer-color-hover; }
      border-top: var(--beam) solid $cc-beamer-beam-color-hover;
    }

    .nav-link {
      font-weight: normal;
      font-size: 0.9em;
      padding: 4px $cc-beamer-nav-spacer;

      .small {
        font-size: 0.7em;
      }
    }

    .spacer {
      border-top: var(--beam) solid $cc-beamer-beam-color;
      min-height: var(--bar);
    }
  }

  .flip.navbar {
    border-top: var(--fin) solid $cc-beamer-border-color;
    border-bottom: 0px;

    .nav-item, .navbar-brand {
      border-bottom: var(--beam) solid $cc-beamer-beam-color;
      border-top: 0px;
      padding-bottom: var(--beam);
      padding-top: 0px;
    }

    .nav-item:hover {
      border-bottom: var(--beam) solid $cc-beamer-beam-color-hover;
      border-top: 0px;
    }

    .spacer {
      border-bottom: var(--beam) solid $cc-beamer-beam-color;
      border-top: 0px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .cc.navbeam {
    .navbar {
      .nav-item, .navbar-brand {
        min-height: var(--bar) / 2;
      }

      .spacer {
        min-height: var(--bar) / 2;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cc.navbeam {
    .navbar {
      .nav-item, .navbar-brand {
        border-top: 0px;
      }

      .nav-item:hover {
        border-top: 0px;
      }

      .spacer {
        border-top: 0px;
      }
    }

    .flip.navbar {
      .nav-item, .navbar-brand {
        border-bottom: 0px;
      }

      .nav-item:hover {
        border-bottom: 0px;
      }

      .spacer {
        border-bottom: 0px;
      }
    }
  }
}

html.dark-mode {
  .cc.navbeam {
    background-color: $cc-beamer-bg-dark;

    .navbar {
      border-bottom-color: $cc-beamer-border-color-dark;

      .navbar-toggler {
        color: $cc-beamer-color-dark;
      }

      .nav-item, .navbar-brand {
        * { color: $cc-beamer-color-dark; }
        border-top-color: $cc-beamer-beam-color-dark;
      }

      .nav-item:hover {
        * { color: $cc-beamer-color-hover-dark; }
        border-top-color: $cc-beamer-beam-color-hover-dark;
      }

      .spacer {
        border-top-color: $cc-beamer-beam-color-dark;
      }
    }

    .flip.navbar {
      border-top-color: $cc-beamer-border-color-dark;

      .nav-item, .navbar-brand {
        border-bottom-color: $cc-beamer-beam-color-dark;
        border-top-color: transparent;
      }

      .nav-item:hover {
        border-bottom-color: $cc-beamer-beam-color-hover-dark;
        border-top-color: transparent;
      }

      .spacer {
        border-bottom-color: $cc-beamer-beam-color-dark;
        border-top-color: transparent;
      }
    }
  }
}
</style>
