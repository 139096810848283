<template>
  <div class="cc xx">
    <div
      v-if="sortedData.length"
      class="timeline">
      <div
        class="timeline__item"
        v-for="(item) in sortedData"
        :style="`--year:'${item.year}';`"
        :key="item._id"
      >
        <div class="content">
          <div class="d-flex justify-content-between">
            <h2
              v-if="item.title.length"
              class="content__heading">
              {{ item.title }}
            </h2>
          </div>
          <p v-if="item.text.length">{{ item.text }}</p>
          <div v-if="item.tags && item.tags.length">
            <span
              class="content__tag"
              v-for="tag in item.tags"
              :key="tag"
            >
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalTimeline',

  props: {
    inputData: {
      type: Array,
      required: true,
      default: () => [],
    },
    reversed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    sortedData() {
      const inputData = this.inputData;

      inputData.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      if (this.reversed) inputData.reverse();

      return inputData;
    }
  }
}
</script>

<style scoped lang="scss">

$timeline-color: $gray-300;
$timeline-color-dark: $gray-700;
$timeline-even-heading: $gray-800;
$timeline-odd-heading: $gray-800;

$timeline-width: 4px;

.cc.xx {
  .timeline {
    @media screen and (min-width: 520px) {
      border-top: $timeline-width solid $timeline-color;
      border-bottom: $timeline-width solid $timeline-color;
    }

    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    & * {
      box-sizing: border-box;
    }

    &::after {
      content: '';
      position: absolute;
      width: $timeline-width;
      background-color: $timeline-color;
      top: 0;
      bottom: 0;
      left: 90%;
      margin-left: -3px;

      @media screen and (min-width: 1024px) {
        left: 50%;
      }
    }

    &__item {
      padding: 10px 40px;
      position: relative;
      background-color: inherit;
      width: 90%;

      @media screen and (min-width: 1024px) {
        width: 50%;
      }

      &::after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        right: -12px;
        background-color: white;
        border: $timeline-width solid $timeline-color;
        top: 50px;
        border-radius: 50%;
        z-index: 1;

        @media screen and (min-width) {
          top: 50px;
        }
      }

      --year: "";

      &:nth-child(odd) {
        left: 0;

        &::before {
          content: var(--year);
          font-weight: normal;
          font-size: 0.8em;
          position: absolute;
          border: 2px solid $gray-600;
          top: $timeline-width;
          right: -30px;
          width: 60px;
          z-index: 2;
          text-align: center;
          padding: 4px;
          padding-bottom: 0px;
          background-color: $timeline-color;
        }

        @media screen and (min-width: 520px) {
          &::before {
            content: var(--year);
            font-weight: normal;
            font-size: 1em;
            position: absolute;
            top: 60px;
            right: 0;
            width: 50%;
            z-index: -1;
            height: 40px;
            text-align: right;
            padding-right: 30px;
            border: 0px;
            border-top: $timeline-width solid;
            border-color: $timeline-color;
            background-color: transparent;
          }
        }

        .content {
          &__heading {
            font-weight: bold;
            color: $timeline-odd-heading;
          }

          @media screen and (min-width: 520px) {
            padding: 20px 50px 20px 30px;
            margin-right: 60px;

            &::before {
              content: '';
              position: absolute;
              height: 15px;
              width: 15px;
              top: 10%;
              right: -5px;
              background-color: $timeline-color;
              border: $timeline-width solid $timeline-color;
              top: 44px;
              border-radius: 50%;
              z-index: 1;
            }
          }
        }
      }

      &:nth-child(even) {
        &::before {
          content: var(--year);
          font-weight: normal;
          font-size: 0.8em;
          border: 2px solid $gray-600;
          position: absolute;
          top: $timeline-width;
          right: -30px;
          width: 60px;
          z-index: 2;
          text-align: center;
          padding: 4px;
          padding-bottom: 0px;
          background-color: $timeline-color;
        }

        @media screen and (min-width: 520px) {
          &::before {
            content: var(--year);
            font-weight: normal;
            font-size: 1em;
            position: absolute;
            top: 60px;
            right: 0;
            width: 50%;
            z-index: -1;
            height: 40px;
            text-align: right;
            padding-right: 30px;
            padding-left: 0px;
            border: 0px;
            border-top: $timeline-width solid;
            border-color: $timeline-color;
            background-color: transparent;

            @media screen and (min-width: 1024px) {
              text-align: left;
              padding-right: 0px;
              padding-left: 30px;
              left: 0;
            }
          }
        }

        @media screen and (min-width: 1024px) {
          left: 50%;
        }

        .content {
          &__heading {
            font-weight: bold;
            color: $timeline-even-heading;
          }

          @media screen and (min-width: 520px) {
            padding: 20px 50px 20px 30px;
            margin: 0 60px 0 0;

            &::before {
              content: '';
              position: absolute;
              height: 15px;
              width: 15px;
              top: 10%;
              right: -5px;
              background-color: $timeline-color;
              border: $timeline-width solid $timeline-color;
              top: 44px;
              border-radius: 50%;
              z-index: 1;

              @media screen and (min-width: 1024px) {
                left: -5px;
              }
            }
          }

          @media screen and (min-width: 1024px) {
            padding: 20px 30px 20px 50px;
            margin: 0 0 0 60px;
          }
        }

        &::after {
          @media screen and (min-width: 1024px) {
            left: -13px;
          }
        }
      }
    }

    .content {
      background-color: white;
      position: relative;

      &__heading {
        font-weight: normal;
        color: $timeline-color;
      }

      &__date {
        font-style: italic;
      }

      &__tag {
        padding: 5px 10px;
        margin-right: 10px;
        background-color: $timeline-color;
        font-size: 0.9em;
      }
    }
  }
}

html.dark-mode {
  .cc.xx {
    .timeline {
      @media screen and (min-width: 520px) {
        border-top: $timeline-width solid $timeline-color-dark;
        border-bottom: $timeline-width solid $timeline-color-dark;
      }

      &::after {
        background-color: $timeline-color-dark;
      }

      .content {
        background-color: $gray-800;

        &__tag {
          background-color: $timeline-color-dark;
        }

        &::before {
          background-color: $timeline-color-dark;
        }
      }

      &__item {
        &::after {
          border-color: $timeline-color-dark;
          background-color: $gray-800;
        }

        &:nth-child(odd), &:nth-child(even) {
          &::before {
            border-color: $timeline-color-dark;
            background-color: $gray-900;
            font-size: 0.8em;
            border: 2px solid $timeline-color-dark;

            @media screen and (min-width: 520px) {
              background-color: $gray-800;
              font-size: 1em;
              border: 0px;
              border-top: $timeline-width solid $timeline-color-dark;
            }
          }

          .content::before {
            border-color: $timeline-color-dark;
          }

          .content__heading {
            font-weight: bold;
            color: $gray-200;
          }
        }
      }
    }
  }
}

</style>
