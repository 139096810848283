<template>
  <main class="cc pagetitle">
    <div v-if="caption"><slot name="caption"><PageCaption :text="caption" /></slot></div>

    <h1 class="cc title bs display-3">
      <slot name="title">{{ title }}</slot>
    </h1>

    <div v-if="subtitle"><slot name="subtitle"><PageSubtitle :text="subtitle" /></slot></div>

    <slot></slot>
  </main>
</template>

<script>
import PageCaption from "@/components/PageCaption.vue";
import PageSubtitle from "@/components/PageSubtitle.vue";

export default {
  name: "PageTitle",
  components: {
    PageCaption,
    PageSubtitle
  },
  props: {
    title: String,
    subtitle: String,
    caption: String
  }
};
</script>

<style lang="scss">
.cc.pagetitle {
  padding-top: 120px;

  .title {
    margin: 0px;
  }
}

@include media-breakpoint-down(sm) {
  .cc.pagetitle {
    padding-top: 60px;
  }
}

</style>
