<template>
  <PageFrame>
    <PageTitle
      title="Let's Connect."
      subtitle="Just&nbsp;hit&nbsp;us&nbsp;up&nbsp;and we&nbsp;will&nbsp;get&nbsp;in&nbsp;touch&nbsp;with&nbsp;you."
    />
  </PageFrame>

  <ContactOptions />

  <PageFrame background="widestickerbomb3">
    <VisualCue>
      <template v-slot:title>
        Drop Us a Message.
      </template>
      <template v-slot:text>
        We would love to go on a journey with you. As a companion, as a contributor, as a comrade. We are ready for the pride and honor to be on your side.
      </template>
    </VisualCue>
  </PageFrame>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactOptions from "@/components/ContactOptions.vue";

export default {
  name: "ContactPage",
  components: {
    PageTitle,
    PageFrame,
    VisualCue,
    ContactOptions
  }
};
</script>

<style lang="scss"></style>
