<template>
  <section class="cc featurelist" aria-label="Portfolio of services">

    <div
      class="bs row"
      role="list"
      aria-label="List of services"
      v-if="features && features.length > 0"
    >
      <div class="cc feature bs col-12 col-lg-6 col-xl-4"
        :class="feature.color"
        v-for="feature in features"
        :key="feature.id"
         @click="navigate(feature.link)"
      >
        <div class="bs d-flex align-items-center">
          <div class="cc featureicon">
            <fa :class="feature.color" :icon="feature.icon"></fa>
          </div>

          <h3 class="cc featuretitle bs flex-fill">
            <router-link :to="feature.link" v-if="feature.link">
              {{ feature.title }}
            </router-link>
            <strong v-if="!feature.link">{{ feature.title }}</strong>
          </h3>
        </div>

        <p class="cc claim">
          {{ feature.claim }}
        </p>

        <div class="cc link bs d-flex">
          <router-link :to="feature.link" class="cc featurelink bs btn btn-secondary mr-auto" :class="feature.color">
            <fa icon="arrow-right"></fa><span class="bs d-none">Read More</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureList",
  methods: {
    navigate(route) {
      this.$router.push(route);
    }
  },
  props: {
    features: Array
  }};
</script>

<style lang="scss">
.cc.featurelist {
  margin-top: 20px;

  .feature {
    margin-top: 20px;
    margin-bottom: 40px;
    padding-top: 0px;
  }

  .feature:hover { cursor: pointer; }
  .feature:hover .featurelink { color: $gray-800; }

  .feature.lilac:hover .claim { border-color: $color-lilac; }
  .feature.blue:hover .claim { border-color: $color-blue; }
  .feature.gold:hover .claim { border-color: $color-gold; }
  .feature.green:hover .claim { border-color: $color-green; }
  .feature.turquoise:hover .claim { border-color: $color-turquoise; }
  .feature.red:hover .claim { border-color: $color-red; }

  .feature.lilac:hover .featurelink { border-color: $color-lilac; }
  .feature.blue:hover .featurelink { border-color: $color-blue; }
  .feature.gold:hover .featurelink { border-color: $color-gold; }
  .feature.green:hover .featurelink { border-color: $color-green; }
  .feature.turquoise:hover .featurelink { border-color: $color-turquoise; }
  .feature.red:hover .featurelink { border-color: $color-red; }

  .claim {
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    font-size: 0.95em;
    border-top: 4px solid $gray-300;
    border-left: 4px solid $gray-300;
  }

  .featurelink {
    font-size: 0.7em;
    font-weight: 400;
    border-width: 0px;
    padding: 10px 20px;
    background-color: $white;
    border-top: 4px solid $gray-300;
    color: $gray-400;
  }

  .featurelink:hover {
      color: $gray-800;
  }

  .featurelink.lilac:hover { border-color: $color-lilac; }
  .featurelink.blue:hover { border-color: $color-blue; }
  .featurelink.gold:hover { border-color: $color-gold; }
  .featurelink.green:hover { border-color: $color-green; }
  .featurelink.turquoise:hover { border-color: $color-turquoise; }
  .featurelink.red:hover { border-color: $color-red; }

  .featureicon {
    padding: 4px 16px 4px 0px;
    color: $primary;

    .lilac { color: $color-lilac; }
    .blue { color: $color-blue; }
    .gold { color: $color-gold; }
    .green { color: $color-green; }
    .turquoise { color: $color-turquoise; }
    .red { color: $color-red; }
  }

  .featuretitle {
    padding-top: 8px;

    a {
      font-weight: 600;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    a:hover {
      text-decoration: none;
      color: $black;
    }
  }
}

@include media-breakpoint-down(lg) {
  .cc.featurelist {
    .feature {
      margin-top: 20px;
      margin-bottom: 0px;
    }

    .claim {
      font-size: 0.85em;
    }
  }
}

@include media-breakpoint-down(md) {
  .cc.featurelist {
    .feature {
      margin-top: 40px;
      padding-top: 0px;
    }

    .feature:first-child {
      margin-top: 20px;
    }
  }
}

html.dark-mode {
  .cc.featurelist {
    .feature:hover .featurelink { color: $light; }

    .featuretitle {
      a:hover {
        color: $white;
      }
    }

    .claim {
      border-top: 4px solid $gray-700;
      border-left: 4px solid $gray-700;
    }

    .featurelink {
      background-color: $gray-800;
      border-top: 4px solid $gray-700;
      color: $gray-600;
    }

    .featurelink:hover {
      color: $light;
    }
  }
}

</style>
