<template>
  <section class="cc visualcue">
    <div class="bs row align-items-end">
      <div class="cc introimg bs col-12 col-lg-6" :class="imageRight ? 'order-1 order-lg-2 text-center text-lg-right' : ''">
        <img
          :src="_imageSource"
          width="512"
          height="384"
          class="cc img bs img-fluid"
          :class="color + ' ' + (imageRight ? 'cc right' : 'cc left')"
          alt="..."
        />
      </div>
      <div class="cc intro bs col-12 col-lg-6 " :class="imageRight ? 'order-2 order-lg-1' : ''">
        <h3 class="bs display-4" :class="color">
          <slot name="title">{{ title }}</slot>
        </h3>

        <p class="cc text" :class="color + ' ' + (imageRight || beamLeft || !beamRight ? 'left' : 'right')">
          <slot name="text">{{ text }}</slot>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VisualCue",
  props: {
    image: String,
    title: String,
    text: String,
    claim: String,
    color: String,
    imageRight: Boolean,
    beamLeft: Boolean,
    beamRight: Boolean
  },
  computed: {
    _imageSource() {
      if (!this.image) return require("@/assets/img/skate-lean-bg-512x384.jpg");
      return require(`@/assets/img/${this.image}-512x384.jpg`);
    }
  }
};
</script>

<style lang="scss">
.cc.visualcue {
  .introimg .img {
    padding: 0px;
    margin: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .intro {
    margin: 0px;
    margin-top: 20px;

    .display-4 {
      font-weight: bold;
    }

    .text {
      margin-top: 40px;
      padding: 40px;
      font-size: 1.1em;
      font-weight: 300;
      background-color: $white;

      &.turquoise { color: $color-turquoise-dark; }
      &.lilac { color: $color-lilac-dark; }
      &.gold { color: $color-gold-dark; }
      &.red { color: $color-red-dark; }
      &.blue { color: $color-blue-dark; }
      &.green { color: $color-green-dark; }

      &.right {
        border-right: 4px solid $gray-600;
      }

      &.left {
        border-left: 4px solid $gray-600;
      }

      &.turquoise { border-color: $color-turquoise; }
      &.lilac { border-color: $color-lilac; }
      &.gold { border-color: $color-gold; }
      &.red { border-color: $color-red; }
      &.blue { border-color: $color-blue; }
      &.green { border-color: $color-green; }
    }
  }
}

@include media-breakpoint-down(lg) {
  .cc.visualcue {
    .intro .text {
      margin-top: 20px;
      font-size: 1em;
      font-weight: 300;
    }
  }
}

@include media-breakpoint-down(md) {
  #visualcue.cc {
    .introimg {
      text-align: center;
    }

    .introimg .img {
      padding: 0px;
      padding-top: 15px;
      margin: 0px;
    }

    .intro {
      margin-top: 20px;

      .text {
        margin-top: 10px;
        font-size: 1em;
        font-weight: 300;

        &.right {
          border-right: none;
          border-bottom: none;
          border-top: 4px solid;
        }

        &.left {
          border-left: none;
          border-bottom: none;
          border-top: 4px solid;
        }

        &.turquoise { border-color: $color-turquoise; }
        &.lilac { border-color: $color-lilac; }
        &.gold { border-color: $color-gold; }
        &.red { border-color: $color-red; }
        &.blue { border-color: $color-blue; }
        &.green { border-color: $color-green; }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cc.visualcue {
    .intro {
      margin-top: 0px;

      .display-4 { display: none; }
      .text {
        margin-top: 0px;
        margin-bottom: 15px;
      }
    }
  }
}

html.dark-mode {
  .cc.visualcue {
    .intro .text {
      background-color: $black;

      &.turquoise { color: $color-turquoise-light; }
      &.lilac { color: $color-lilac-light; }
      &.gold { color: $color-gold-light; }
      &.red { color: $color-red-light; }
      &.blue { color: $color-blue-light; }
      &.green { color: $color-green-light; }

      &.turquoise { border-color: $color-turquoise-light; }
      &.lilac { border-color: $color-lilac-light; }
      &.gold { border-color: $color-gold-light; }
      &.red { border-color: $color-red-light; }
      &.blue { border-color: $color-blue-light; }
      &.green { border-color: $color-green-light; }
    }
  }
}

</style>
