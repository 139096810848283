<template>
  <section
    class="cc pageposter background widestickerbomb"
    :class="tint ? color : ''"
  >
    <div class="bs d-flex flex-column-reverse flex-md-row justify-content-start">
      <div class="cc topic" :class="color">
        <h5 class="cc title" :class="tint ? color : ''"><slot name="title">{{ title }}</slot></h5>
        <div class="cc description">
          <slot name="description">{{ description }}</slot>
        </div>
      </div>
      <div class="cc poster bs flex-grow-0 flex-md-grow-1" :class="color">
        <div class="bs display-3" :class="tint ? color : ''"><slot name="poster">{{ poster }}</slot></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PagePoster",
  components: {
  },
  props: {
    title: String,
    description: String,
    poster: String,
    color: {
      type: String,
      default: "default"
    },
    tint: Boolean
  }
};
</script>

<style lang="scss">
.cc.pageposter {
  margin-bottom: 40px;

  &.widestickerbomb {
    background-size: cover;
    background-image: url(../assets/img/stickerbomb-bg4-1920x360.jpg);
    box-shadow: inset 0 0 0 50vw rgba($white,0.9);
  }

  &.background {
    width: 100%;
    height: auto;

    &.turquoise { box-shadow: inset 0 0 0 50vw rgba($color-turquoise-light, 0.9) };
    &.lilac { box-shadow: inset 0 0 0 50vw rgba($color-lilac-light, 0.9) };
    &.gold { box-shadow: inset 0 0 0 50vw rgba($color-gold-light, 0.9) };
    &.red { box-shadow: inset 0 0 0 50vw rgba($color-red-light, 0.9) };
    &.blue { box-shadow: inset 0 0 0 50vw rgba($color-blue-light, 0.9) };
    &.green { box-shadow: inset 0 0 0 50vw rgba($color-green-light, 0.9) };
  }

  .topic {
    min-height: 120px;
    width: 40%;
    padding: 40px;
    border-top: 4px solid $gray-600;
    background-color: $gray-200;

    .description {
      margin-top: 40px;
      margin-bottom: 0px;
      font-size: 0.8em;
    }

    .title {
      &.turquoise { color: $color-turquoise-dark; }
      &.lilac { color: $color-lilac-dark; }
      &.gold { color: $color-gold-dark; }
      &.red { color: $color-red-dark; }
      &.blue { color: $color-blue-dark; }
      &.green { color: $color-green-dark; }
    }

    &.turquoise {
      border-top: 4px solid $color-turquoise;
      background-color: $color-turquoise-light;
    }

    &.lilac {
      border-top: 4px solid $color-lilac;
      background-color: $color-lilac-light;
    }

    &.gold {
      border-top: 4px solid $color-gold;
      background-color: $color-gold-light;
    }

    &.red {
      border-top: 4px solid $color-red;
      background-color: $color-red-light;
    }

    &.blue {
      border-top: 4px solid $color-blue;
      background-color: $color-blue-light;
    }

    &.green {
      border-top: 4px solid $color-green;
      background-color: $color-green-light;
    }
  }

  .poster {
    padding: 40px;

    border-left: 4px solid $gray-600;
    border-bottom: 4px solid $gray-600;

    .display-3 {
      font-size: 2.4em;
      font-weight: bold;
      color: $gray-800;

      &.turquoise { color: $color-turquoise-dark; }
      &.lilac { color: $color-lilac-dark; }
      &.gold { color: $color-gold-dark; }
      &.red { color: $color-red-dark; }
      &.blue { color: $color-blue-dark; }
      &.green { color: $color-green-dark; }
    }

    &.turquoise {
      border-left: 4px solid $color-turquoise;
      border-bottom: 4px solid $color-turquoise;
    }

    &.lilac {
      border-left: 4px solid $color-lilac;
      border-bottom: 4px solid $color-lilac;
    }

    &.gold {
      border-left: 4px solid $color-gold;
      border-bottom: 4px solid $color-gold;
    }

    &.red {
      border-left: 4px solid $color-red;
      border-bottom: 4px solid $color-red;
    }

    &.blue {
      border-left: 4px solid $color-blue;
      border-bottom: 4px solid $color-blue;
    }

    &.green {
      border-left: 4px solid $color-green;
      border-bottom: 4px solid $color-green;
    }
  }
}


@include media-breakpoint-down(md) {
  .cc.pageposter {
    .topic {
      min-height: 120px;
      width: auto;
      padding: 40px;
    }

    .poster {
      .display-3 {
        font-size: 1.8em;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cc.pageposter {
    .topic {
      .description {
        margin-top: 10px;
      }
    }

    .poster {
      border-left: none !important;
      border-bottom: none !important;

      .display-3 {
        font-size: 1.4em;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cc.pageposter {
    .topic {
      padding-left: unquote("calc(((100vw - 1140px) / 2) + 15px)");
    }
  }
}

@include media-breakpoint-between(md, lg) {
  .cc.pageposter {
    .topic {
      padding-left: unquote("calc(((100vw - 960px) / 2) + 15px)");
    }
  }
}

@include media-breakpoint-between(sm, md) {
  .cc.pageposter {
    .topic {
      padding-left: unquote("calc(((100vw - 720px) / 2) + 15px)");
    }
  }
}

@include media-breakpoint-between(xs, sm) {
  .cc.pageposter {
    .topic, .poster {
      padding-left: unquote("calc(((100vw - 540px) / 2) + 15px)");
    }
  }
}

@include media-breakpoint-down(xs) {
  .cc.pageposter {
    .topic, .poster {
      padding-left: 15px;
    }
  }
}

html.dark-mode {
  .cc.pageposter {
    &.widestickerbomb {
      box-shadow: inset 0 0 0 50vw rgba($black,0.9);
    }

    &.turquoise { box-shadow: inset 0 0 0 50vw rgba($color-turquoise-dark, 0.9) };
    &.lilac { box-shadow: inset 0 0 0 50vw rgba($color-lilac-dark, 0.9) };
    &.gold { box-shadow: inset 0 0 0 50vw rgba($color-gold-dark, 0.9) };
    &.red { box-shadow: inset 0 0 0 50vw rgba($color-red-dark, 0.9) };
    &.blue { box-shadow: inset 0 0 0 50vw rgba($color-blue-dark, 0.9) };
    &.green { box-shadow: inset 0 0 0 50vw rgba($color-green-dark, 0.9) };

    .topic {
      border-color: $gray-400;
      background-color: $gray-900;

      .title {
        &.turquoise { color: $color-turquoise-light; }
        &.lilac { color: $color-lilac-light; }
        &.gold { color: $color-gold-light; }
        &.red { color: $color-red-light; }
        &.blue { color: $color-blue-light; }
        &.green { color: $color-green-light; }
      }

      &.turquoise {
        border-color: $color-turquoise-light;
        background-color: $color-turquoise-dark;
      }

      &.lilac {
        border-color: $color-lilac-light;
        background-color: $color-lilac-dark;
      }

      &.gold {
        border-color: $color-gold-light;
        background-color: $color-gold-dark;
      }

      &.red {
        border-color: $color-red-light;
        background-color: $color-red-dark;
      }

      &.blue {
        border-color: $color-blue-light;
        background-color: $color-blue-dark;
      }

      &.green {
        border-color: $color-green-light;
        background-color: $color-green-dark;
      }
    }

    .poster {
      border-color: $gray-400;

      .display-3 {
        color: $gray-400;
        
        &.turquoise { color: $color-turquoise-light; }
        &.lilac { color: $color-lilac-light; }
        &.gold { color: $color-gold-light; }
        &.red { color: $color-red-light; }
        &.blue { color: $color-blue-light; }
        &.green { color: $color-green-light; }
      }

      &.turquoise {
        border-color: $color-turquoise-light;
      }

      &.lilac {
        border-color: $color-lilac-light;
      }

      &.gold {
        border-color: $color-gold-light;
      }

      &.red {
        border-color: $color-red-light;
      }

      &.blue {
        border-color: $color-blue-light;
      }

      &.green {
        border-color: $color-green-light;
      }
    }
  }
}
</style>
