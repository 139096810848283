<template>
  <PageFrame>
    <PageTitle
      title="About Our Story."
      subtitle="We went the hard way to make your way smooth."
    />
  </PageFrame>

  <PagePoster
    description="While most modern digital products focus on fast development and learning, the heart and soul of Agility is often ignored: building the product with the customer while innovating with designers &amp; engineers."
  >
    <template v-slot:title><TextLogo /></template>
    <template v-slot:description>
      <q>Constructive Collaboration Labs</q>.<br/>
      <br/>
      The art &amp; science of modern people management applied in the real world.
    </template>
    <template v-slot:poster>
      Constructive<br />
      Collaboration<br />
      Labs.
    </template>
  </PagePoster>

  <PageFrame no-spacing>
    <p class="cc afterposter">
      This is the project I have been working on for more than a decade. CCLABS culminates my 18 years of agile development, modern management and innovative leadership experience, combined with the ultimate discretion, dedication, exclusiveness and excellence to help you shaping your future self, success and happiness.
    </p>
  </PageFrame>

  <PageFrame>
    <VisualCue title="I go by Ilker." image="ilker" beam-left>
      <template v-slot:text>
        Hi, my name is Ilker and you are right now looking to a part of my life. I am happy that you are interested in me!<br/><br/>You can call me <mark>your companion.</mark>
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame>
    <p>
      Throughout my entire career I strived and worked towards being a companion for my peers and teams. And this is exactly what I am continuing to do, except with now more than two decades of experience on companionship in modern, dynamic, fast-growing, agile environments.
    </p>

    <h2 class="bs display-4">
      Some Blessings and Scars
    </h2>

    <p>
      Let me share a few remarkable moments of my journey so far. Everybody has an exciting story to tell, so do you and I. That is why I am happy to share a little bit of my story here with you. Oh, and I'm looking forward to get to know a bit of your story as well.
    </p>

    <div class="cc timeline">
      <VerticalTimeline :input-data="timeline" />
    </div>

    <h2 class="cc aftertimeline bs display-4">
      You and Me
    </h2>

    <p>
      Maybe tomorrow I will get to know you as well? What is for sure is that you can decide. It is up to you if we share a part of our journey shoulder to shoulder, together, as companions.<br />
      <strong>I am ready</strong>.
    </p>

    <ContactAction teaser="Interested in working with me?" subtext="Hit me up and we will rock and roll!" />
  </PageFrame>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PagePoster from "@/components/PagePoster.vue";
import ContactAction from "@/components/ContactAction.vue";
import VisualCue from "@/components/VisualCue.vue";
import VerticalTimeline from "@/components/VerticalTimeline.vue";
import TextLogo from "@/components/TextLogo.vue";

export default {
  name: "AboutPage",
  components: {
    PageTitle,
    PageFrame,
    PagePoster,
    ContactAction,
    TextLogo,
    VerticalTimeline,
    VisualCue
  },
  data() {
    return {
      timeline: [
        {
          "_id": "1",
          "title": "Independent Programmer",
          "text": "Yes, in 1996 many Software Engineers called themselves Programmers. The beginning of my professional career was in consumer and gaming software industries and I enjoyed every minute of it.",
          "date": "June 20, 1996 4:17 AM",
          "year": "1996"
        },
        {
          "_id": "2",
          "title": "Study of Computer Science",
          "text": "I owned computers since I was 11 years old. First it was all fun and games, but it got serious quickly. I wrote my first program at age of 13 and by the age of 15 I substantially contributed to the Amiga Demo and Gaming Scene. From that moment on, I knew my future job needs to be anything with computers.",
          "date": "May 12, 1997 8:39 AM",
          "year": "1997"
        },
        {
          "_id": "3",
          "title": "College Dropout",
          "text": "I got bored by the all theoretical stuff at university and youthfully believed to be a part of the dot-com-hype right before millenium. I barely made a living by squashing the millenium bug out of whatever software system was in front of me.",
          "date": "May 12, 1999 8:39 AM",
          "year": "1999"
        },
        {
          "_id": "45",
          "title": "Kaizen, Kanban, TPS",
          "text": "Working in regulatory and mobility industries, I quickly got exposed to Lean and Lean Manufacturing, the Toyota Production System and the Kaizen philosophy. In very early years, this experience substantially influenced my future self as Manager and Coach.",
          "date": "January 14, 2002 8:39 AM",
          "year": "2002"
        },
        {
          "_id": "5",
          "title": "XP Teamwork",
          "text": "My programming skills advanced fast as I got to learn new languages and work within a team. At the same time this was my very first exposure to XP (extreme programming) and Agile Software Development. My Agile roots came from those years of real-life extreme programming.",
          "date": "January 14, 2003 8:39 AM",
          "year": "2003"
        },
        {
          "_id": "6",
          "title": "Leader of XP & Agile",
          "text": "With years of extraordinary cool experience of XP and Agile practices, I advanced to lead my first software engineering teams within a radipdly growing organisation.",
          "date": "January 14, 2007 8:39 AM",
          "year": "2007"
        },
        {
          "_id": "7",
          "title": "Agile Transformation with Scrum",
          "text": "Within a large division of software teams, we altogether tackled a heavy transformation towards Agile principles and practices using the Scrum framework. I did my Scrum certifications and worked with incredibly inspiring people.",
          "date": "January 14, 2008 8:39 AM",
          "year": "2008"
        },
        {
          "_id": "9",
          "title": "TDD & Coding Dojos",
          "text": "While practicing on my TDD skills, I founded and led the first and industry-renowed Coding Dojo in Germany with two other great companions. I was head of the largest Coding Dojo and Code Kata practitioner group for many years.",
          "date": "September 14, 2009 8:39 AM",
          "year": "2009"
        },
        {
          "_id": "95",
          "title": "Signing Agile Manifesto",
          "text": "After many years of practicing and experiencing Agile Software Development, with XP, Scrum, Crystal, Kanban - practicing TDD, Pair Programming, Continuous Delivery and many more - I felt that I truly accomplished the Agile philosophy and mindset. I signed the Agile Manifesto.",
          "date": "September 14, 2009 8:39 AM",
          "year": "2009"
        },
        {
          "_id": "10",
          "title": "Scalable Architecture",
          "text": "Being both a leader of people and an expert of distributed, scalable service-oriented architectures, I took the responsibility on shaping and guiding all Scrum & Kanban software engineering teams as Senior Architect.",
          "date": "December 14, 2009 8:39 AM",
          "year": "2009"
        },
        {
          "_id": "11",
          "title": "Burn-Out",
          "text": "Being a high-performer for 10+ years, always working my ass off to prove that my skills and capabilities as a college dropout are at least as good or even better than most engineers with a PhD, my body and mind pulled the emergency brake. I suffered a dramatic, long-lasting burn-out with two never-ending years of anxiety, panic attacks, black-outs and numerous health challenges.",
          "date": "January 14, 2010 8:39 AM",
          "year": "2010"
        },
        {
          "_id": "12",
          "title": "Consultancy Career",
          "text": "Yes, right at my burn-out times, I changed my career path to consulting. Hands down this was one of the best decisions I made at that time. I learned to perform even with suffering and struggle, while learning a huge amount about psychology, systems thinking, social systems and organisational development thanks to great mentors and people in my consultancy teams.",
          "date": "June 14, 2010 8:39 AM",
          "year": "2010"
        },
        {
          "_id": "13",
          "title": "Agile Coaching",
          "text": "During my consultancy times, I often got assigned to projects where Agile Transformation was a crucial part of the objective. I worked as Agile Coach, Scrum Master, Agile Practitioner or Organisational Consultant in very large enterprises and expanding small-to-medium businesses.",
          "date": "June 14, 2011 8:39 AM",
          "year": "2011"
        },
        {
          "_id": "14",
          "title": "Student of Martial Arts",
          "text": "In my toughest times of anxiety and panic attacks, I realised that mental and physical activity tremendously helps overcoming my symptoms. I am very proud and grateful to have exeperienced the soul-, spirit- and body-changing practice of Choy-Lee-Fut martial arts.",
          "date": "June 15, 2011 8:39 AM",
          "year": "2011"
        },
        {
          "_id": "15",
          "title": "Toastmaster CC",
          "text": "As a consequence of my heavily communication-based work, I got interested and involved in public speaking as well as rhetorical advancement. I joined the Toastmasters community and sharpened my communication skills within a greatly supportive and heartwarming community.",
          "date": "March 14, 2012 8:39 AM",
          "year": "2012"
        },
        {
          "_id": "16",
          "title": "Missing my Kids",
          "text": "The flipside of my advancement of my so-called 2nd career as Consultant and Coach was my constant travel. Which in turn led to almost zero quality time with my two wonderful kids. After a while, my soul as a father couldn't bear this circumstance anymore. I made the best decision possible: turn down my job and be with my kids.",
          "date": "March 10, 2013 8:39 AM",
          "year": "2013"
        },
        {
          "_id": "17",
          "title": "Scale-Up Challenge, Part 1",
          "text": "I accepted the challenge of scaling up a single software development team to more than a dozen teams within 3 years. Starting from technology whilst carefully crafting the new units, departments, I learned a lot on heavy-lifting the extraordinary hiring, onboarding and teaming constraints and opportunities.",
          "date": "March 10, 2014 8:39 AM",
          "year": "2014"
        },
        {
          "_id": "19",
          "title": "Hell On Earth",
          "text": "The year I turned forty was probably the toughest, scariest, stressfullest, cruelest, devastating time I had to live through until today. While being productive and performing at work, I experienced extreme misery and disasters in my private life, one of them being the passing of my dad, which I respect and love until eternity and beyond.",
          "date": "April 6, 2015 8:39 AM",
          "year": "2015"
        },
        {
          "_id": "20",
          "title": "Agile Misfits",
          "text": "As we grew our department to almost a dozen agile teams with best practices and methods from Agility, the organisation around us got affected by the systemic organisational changes as well. We got significant pushback from our environment to our new organisational management.",
          "date": "February 6, 2016 8:39 AM",
          "year": "2016"
        },
        {
          "_id": "21",
          "title": "Ilker, you're fired!",
          "text": "I was always willing to risk my job for my beliefs and principles, be it professional or ethical. Well, sometimes taking the risk means taking the consequences as well. As avantgarde and agile practices were diluted and transistioned to rather traditional approaches, I became a misfit as well and got fired.",
          "date": "April 2, 2017 8:39 AM",
          "year": "2017"
        },
        {
          "_id": "22",
          "title": "Success follows failure",
          "text": "As much as I was in disbelief about being fired, as much I was proud about my achievements in my past organisation. Along with brave and great people, I achieved scaling up a department from 10 to 80 members, introducing and practicing feature-oriented, cross-functional development.",
          "date": "April 3, 2017 8:39 AM",
          "year": "2017"
        },
        {
          "_id": "24",
          "title": "Scale-Up Challenge, Part 2",
          "text": "It didn't even took a long time to take on an even more challenging scale-up again. This time, the organisation was already developed to many teams, already practising Agile methods and principles. What was missing was a coherent technical, competence-driven leadership. Challenge accepted!",
          "date": "September 2, 2017 8:39 AM",
          "year": "2017"
        },
        {
          "_id": "25",
          "title": "Mentoring & Guiding",
          "text": "With a distinguished and powerful role within the organisation, my objective was to mentor and guide individuals, teams, leaders and high potentials. The most rewarding thing is to see people glow up after you have been coaching and nudging them to their own purpose and happiness.",
          "date": "June 2, 2018 8:39 AM",
          "year": "2018"
        },
        {
          "_id": "27",
          "title": "Nothing is eternal",
          "text": "They say nothing lasts forever, and this is what this time of my life has demonstrated me in Full-HD quality. Youth isn't forever, health isn't forever, love isn't forever, life isn't forever. I (re-)learned to appreciate and love every second of my life.",
          "date": "January 2, 2019 8:39 AM",
          "year": "2019"
        },
        {
          "_id": "28",
          "title": "Back to Basics",
          "text": "After succeeding on my second Scale-Up challenge while dealing with dramatic changes in my private life, it was about time for fresh air. I accepted an assignment to lead a mid-sized IT department, knowing the challenge being the incomplete structural and fragile organisational practices in there.",
          "date": "September 2, 2019 8:39 AM",
          "year": "2019"
        },
        {
          "_id": "29",
          "title": "Connecting the Value Stream",
          "text": "A distinct and deliberate organisational change in aligning cross-functional teams to business-driven value streams relieved the stress and opened up a new level of productivity to the organisation.",
          "date": "May 2, 2020 8:39 AM",
          "year": "2020"
        },
        {
          "_id": "30",
          "title": "Ready for CCLABS",
          "text": "After all those years and experiences, I proved to myself that I am not only capable, but fully ready to make it come true: the CCLABS, consulting and coaching of modern, progressive, agile, sustainable and humanistic management at top-notch level.",
          "date": "August 19, 2020 8:39 AM",
          "year": "2020"
        },
        {
          "_id": "31",
          "title": "Independent Consultant",
          "text": "Today, I am a recognised expert and master of Agility, Modern Management, Leadership and Digital Product Development. I work with full discretion and dedication for large Enterprises, Unicorns and Start-Ups. It makes me happy to be their companion on their way to the future.",
          "date": "March 19, 2021 8:39 AM",
          "year": "2021"
        }
      ]
    }
  }
};
</script>

<style lang="scss">
.cc.afterposter {
  margin-top: 20px;
}

.cc.aftertimeline {
  margin-top: 60px;
}
</style>
