<template>
  <div class="cc navcontext"
    :class="mode + ' ' + (fixed ? 'fixed-top' : (sticky ? 'sticky-top' : ''))"
    :style="`top:${offset};`"
  >
    <nav
      class="bs navbar navbar-expand-lg navbar-light"
      :aria-label="name"
      :class="flip ? 'cc flip' : ''"
      :style="`--fin:${fin};`"
    >
      <div class="cc spacer bs flex-fill" :style="`--beam:${beam}; --bar:${bar};`"></div>

      <div class="bs container">
        <button class="bs navbar-toggler" type="button" data-toggle="collapse"
          :style="`--beam:${beam}; --bar:${bar};`"
          :data-target="`#${name}-nav-list`"
          :aria-controls="`${name}-nav-list`"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <fa icon="ellipsis-h"></fa>
        </button>

        <div class="cc spacer bs order-lg-1 d-flex flex-fill align-items-stretch" :style="`--beam:${beam}; --bar:${bar};`">
          <slot name="title"></slot>
        </div>

        <div class="bs order-lg-2 collapse navbar-collapse" :id="`${name}-nav-list`">
          <ul class="bs navbar-nav">
            <li class="bs nav-item" v-for="item in items" :key="item.key" :class="beamColor" :style="`--beam:${beam}; --bar:${bar};`">
              <router-link :to="{ name: item.route }" class="bs nav-link text-nowrap" data-toggle="collapse" :data-target="`#${name}-nav-list.show`">
                <span :class="small ? 'small' : ''" class="cc itemicon" v-if="item.icon && (item.iconOnLeft || !item.iconOnRight)"><fa :icon="item.icon"></fa></span>
                <span :class="small ? 'small' : ''">{{ item.title }}</span>
                <span :class="small ? 'small' : ''" class="cc itemicon" v-if="item.icon && item.iconOnRight"><fa :icon="item.icon"></fa></span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="cc spacer bs flex-fill" :style="`--beam:${beam}; --bar:${bar};`"></div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "NavContext",
  props: {
    name: String,
    items: Object,
    small: Boolean,
    fixed: Boolean,
    sticky: Boolean,
    flip: Boolean,
    beam: {
      type: String,
      default: "8px"
    },
    beamColor: {
      type: String,
      default: "default"
    },
    mode: {
      type: String,
      default: ""
    },
    bar: {
      type: String,
      default: "60px"
    },
    fin: {
      type: String,
      default: "0px"
    },
    offset: String
  }
};
</script>

<style lang="scss">

$cc-beamer-bg: $white;
$cc-beamer-bg-dark: $dark;

$cc-beamer-color: $dark;
$cc-beamer-color-dark: $light;

$cc-beamer-color-hover: $black;
$cc-beamer-color-hover-dark: $white;

$cc-beamer-border-color: $gray-200;
$cc-beamer-border-color-dark: $black;

$cc-beamer-beam-color: $gray-200;
$cc-beamer-beam-color-dark: $black;

$cc-beamer-beam-color-hover: $gray-600;
$cc-beamer-beam-color-hover-dark: $info;

$cc-beamer-nav-spacer: 15px;

.cc.navcontext {
  background-color: $cc-beamer-bg;

  .navbar {
    padding: 0px;
    border-bottom: var(--fin) solid $cc-beamer-border-color;

    .container {
      padding: 0px;
    }

    .navbar-brand {
      padding-left: $cc-beamer-nav-spacer;
      padding-right: $cc-beamer-nav-spacer;
    }

    .navbar-collapse {
      flex-grow: 0;
    }

    .navbar-toggler {
      border: 0px;
      padding: 0px $cc-beamer-nav-spacer;
      min-height: var(--bar);
    }

    .nav-item, .navbar-brand {
      * { color: $cc-beamer-color; }
      border-top: var(--beam) solid $cc-beamer-beam-color;
      min-height: var(--bar);
      padding-top: var(--beam);
    }

    .nav-item:hover {
      * { color: $cc-beamer-color-hover; }
      border-top: var(--beam) solid $cc-beamer-beam-color-hover;
    }

    .nav-item.lilac:hover { border-top-color: $color-lilac; }
    .nav-item.blue:hover { border-color: $color-blue; }
    .nav-item.gold:hover { border-color: $color-gold; }
    .nav-item.green:hover { border-color: $color-green; }
    .nav-item.turquoise:hover { border-color: $color-turquoise; }
    .nav-item.red:hover { border-color: $color-red; }

    .nav-link {
      padding: 4px $cc-beamer-nav-spacer;
    }

    .spacer {
      border-top: var(--beam) solid $cc-beamer-beam-color;
      min-height: var(--bar);
    }

    .itemicon {
      padding: 0px 8px;
    }
  }

  .flip.navbar {
    border-top: var(--fin) solid $cc-beamer-border-color;
    border-bottom: 0px;

    .nav-item, .navbar-brand {
      border-bottom: var(--beam) solid $cc-beamer-beam-color;
      border-top: 0px;
      padding-bottom: var(--beam);
      padding-top: 0px;
    }

    .nav-item:hover {
      border-bottom: var(--beam) solid $cc-beamer-beam-color-hover;
      border-top: 0px;
    }

    .spacer {
      border-bottom: var(--beam) solid $cc-beamer-beam-color;
      border-top: 0px;
    }
  }
}

.cc.navcontext.warning {
  .navbar {
    .nav-item:hover {
      border-top-color: $black !important;
    }
  }

  .flip.navbar {
    .nav-item:hover {
      border-bottom-color: $black !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .cc.navcontext {
    .navbar {
      .nav-item, .navbar-brand {
        border-top: 0px;
      }

      .nav-item:hover {
        border-top: 0px;
      }

      .navbar-toggler {
        border-top: 0px;
        padding-left: 0px;
      }

      .cc.spacer {
        border-top: 0px;
      }
    }

    .flip.navbar {
      .nav-item, .navbar-brand {
        border-bottom: 0px;
      }

      .nav-item:hover {
        border-bottom: 0px;
      }

      .spacer {
        border-bottom: 0px;
      }
    }
  }
}

html.dark-mode {
  .cc.navcontext {
    background-color: $cc-beamer-bg-dark;

    .navbar {
      border-bottom-color: $cc-beamer-border-color-dark;

      .nav-item, .navbar-brand {
        * { color: $cc-beamer-color-dark; }
        border-top-color: $cc-beamer-beam-color-dark;
      }

      .nav-item:hover {
        * { color: $cc-beamer-color-hover-dark; }
        border-top-color: $cc-beamer-beam-color-hover-dark;
      }

      .spacer {
        border-top-color: $cc-beamer-beam-color-dark;
      }
    }

    .flip.navbar {
      border-top-color: $cc-beamer-border-color-dark;

      .nav-item, .navbar-brand {
        border-bottom-color: $cc-beamer-beam-color-dark;
        border-top-color: transparent;
      }

      .nav-item:hover {
        border-bottom-color: $cc-beamer-beam-color-hover-dark;
        border-top-color: transparent;
      }

      .spacer {
        border-bottom-color: $cc-beamer-beam-color-dark;
        border-top-color: transparent;
      }
    }
  }

  .warning {
    .navbar {
      .nav-item:hover {
        border-top-color: $warning !important;
      }
    }

    .flip.navbar {
      .nav-item:hover {
        border-bottom-color: $warning !important;
        border-top: 0px;
      }
    }
  }
}
</style>
