<template>
  <PageFrame>
    <PageTitle
      title="Pro-Bono Mentoring."
      subtitle="We don't care about your now, we care about your tomorrow."
    />
  </PageFrame>

  <PageContextBar title="Pro-Bono Mentoring" pricetag="€ Free Of Charge" icon="bahai" icon-color="green" sticky-on-desktop :items="tableofcontents" />

  <PageFrame background="widestickerbomb3">
    <VisualCue color="green" image="green-peer" image-right>
      <template v-slot:title>
        The Way to Yourself.
      </template>
      <template v-slot:text>
        There are talents and hard working people out there, struggling with disadvantages, poverty or discrimination. We care about making&nbsp;you&nbsp;stronger.
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame no-spacing>
    <p>
      Starting a business is hard, even though it got so much easier nowadays. Constant attention on new ideas, innovation as well as all the tools and technology enables individuals to do amazing things.
    </p>
    <p>
      Still, what is in fact a lot harder is running and growing the business. Building up a loyal customer relationship, constantly evolving and improving the product and getting the right people for the right job.
    </p>

    <h2 class="display-4">
      We care about the future
    </h2>

    <p>
      We are very thankful and gifted to work for very prestigeous clients and companies. We have decades of experience in scaling Start-Ups, modern people management and developing products for customer excitement.
    </p>
    <p>
      Most importantly, we care to not only help the ones being able to get helped, but help those as well who might not be able to get our assistance by social, financial or professional means.
    </p>

    <h2 class="display-4">
      Tell us about your future self
    </h2>

    <p>
      It simply doesn't matter whether you are a broke mid-life-crisis purpose-seeking dude, a teenager having dreams and a drive to change the world, a hard working college-dropout now trying to build up your life, or a disabled genius being constantly reduced to your inabilities rather than praised for your potential.
    </p>
  </PageFrame>

  <PagePoster
    title="Ikigai &amp; Ichigo Ichie"
    description="Any vision nourishes with dedication, discipline and determination. Still, there is more to it. A vision is only in reach as long as you are in reach with yourself. We can help you find the way."
    color="green"
  >
    <template v-slot:poster>
      Care<br />
      About<br />
      Your Vision.
    </template>
  </PagePoster>

  <PageFrame>
    <p>
      This is not about us, our ethics or morale, it is certainly and seriously about those people who might need a helping hand.
    </p>

    <p>
      If you happen to know someone being one of those people, or even you feel you are among them: Be brave and contact us right now.
    </p>

    <PriceCard title="Pro-Bono&nbsp;Mentoring" companyprice="for free" personprice="for free" />

    <ContactAction
      teaser="It is not a problem to ask and receive assistance when in need."
      subtext="It is a problem to not having tried having a helping hand when in need."
      cta="Let's shape the future"
    />
  </PageFrame>

  <PageLabels title="What we can discuss and train with you" :labels="labels" />
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import PagePoster from "@/components/PagePoster.vue";
import PriceCard from "@/components/PriceCard.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactAction from "@/components/ContactAction.vue";
import PageLabels from "@/components/PageLabels.vue";

export default {
  name: "ProBonoMentoringPage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    PagePoster,
    PriceCard,
    VisualCue,
    ContactAction,
    PageLabels
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "arrow-right",
          iconOnRight: true,
          title: "Leadership Coaching",
          route: "leadershipcoaching"
        }
      ],
      labels: [
        { name: "Ikigai & Ichigo Ichie" },
        { name: "Rhetorical Training" },
        { name: "Constructivism" },
        { name: "Storytelling" },
        { name: "Management 3.0 + 4.0" },
        { name: "Agile Mindset" },
        { name: "Servant Leadership" },
        { name: "Holocracy" },
        { name: "Heterarchies" }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
