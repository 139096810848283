<template>
  <section class="cc pricecard bs row align-items-end" aria-label="Pricing">
    <div class="bs col-12 col-lg-6 col-xl-7">
      <h3 class="cc title">
        <span v-if="personprice != 'free of charge'">Pricing For </span>{{ title }}
      </h3>

      <p class="cc pricenote bs small" v-if="personprice != 'on request'">
        We distinguish between consulting for entire organisations and consulting for individuals within organisations. We mainly operate in european countries. World-wide assignments only upon request.
      </p>

      <p class="cc pricenote bs small" v-if="personprice == 'on request'">
        We only serve one single executive at a time to ensure total and laser-sharp focus. Our companionship is based on full discretion and dedication. All assignments only upon request after thorough aquaintance.
      </p>
    </div>

    <div class="cc pricelist bs col-12 col-lg-6 col-xl-5">
      <div class="cc individualrow bs row no-gutters" v-if="personprice != 'on request'">
        <div class="bs col-6 col-lg-4 d-flex align-items-top">
          <fa class="cc pricetitleicon ind" icon="user"></fa> <h6 class="cc pricetitle ind bs small">Individuals:</h6>
        </div>
        <div class="bs col-6 col-lg-8">
          <div class="cc personprice bs text-right" v-if="personprice == 'up to you'">
            pay what you want
          </div>

          <div class="cc personprice bs text-right" v-if="personprice == 'for free'">
            free as in free of charge
          </div>

          <div class="cc personprice bs text-right" v-if="!(personprice == 'up to you' || personprice == 'on request' || personprice == 'for free')">
            {{ personprice }}&nbsp;/&nbsp;day
          </div>
        </div>
      </div>

      <div class="cc individualrow row no-gutters" v-if="personprice == 'on request'">
        <div class="bs col-12">
          <span class="cc pricetitle ind small">We only serve one single executive at a time.</span>
        </div>
      </div>

      <div class="bs row">
        <div class="bs col-5 col-lg-4 d-flex align-items-top">
          <fa class="cc pricetitleicon" icon="users" v-if="personprice != 'on request'"></fa>
          <fa class="cc pricetitleicon" icon="user-tie" v-if="personprice == 'on request'"></fa>
          <h6 class="cc pricetitle bs small" v-if="personprice != 'on request'">Organisations:</h6>
          <h6 class="cc pricetitle bs small" v-if="personprice == 'on request'">Executives:</h6>
        </div>
        <div class="bs col-7 col-lg-8">
          <div class="cc price bs text-right">
            {{ companyprice }}<span class="cc perday" v-if="!(personprice == 'up to you' || personprice == 'on request' || personprice == 'for free')">&nbsp;/&nbsp;day</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PriceCard",
  props: {
    title: String,
    companyprice: String,
    personprice: String
  }
};
</script>

<style lang="scss">
.cc.pricecard {
  padding-bottom: 10px;

  .individualrow {
    border-bottom: 1px solid $gray-400;
    padding-bottom: 10px;
  }

  .pricelist {
    padding-bottom: 20px;
  }

  .price {
    font-size: 2em;
    font-weight: 300;
  }

  .perday {
    font-size: 0.6em;
  }


  .pricenote {
    margin-top: 10px;
    font-weight: 300;
  }

  .pricetitle {
    font-size: 0.8em;
    margin: 0px;
    margin-top: 10px;

    &.ind {
      font-weight: 300;
      font-style: normal;
      color: $gray-600;
    }
  }

  .pricetitleicon {
    font-size: 0.8em;
    margin-top: 10px;
    margin-right: 10px;
    min-width: 24px;

    &.ind {
      font-weight: 300;
      font-style: normal;
      color: $gray-600;
    }
  }

  .personprice {
    margin-top: 6px;
    font-size: 0.8em;
    font-weight: 300;
  }

  .cc.title {
    padding-top: 8px;
    font-weight: 600;
  }
}


@include media-breakpoint-down(lg) {
  .cc.pricecard {
    .price {
      font-size: 1.8em;
      font-weight: 300;
    }
  }
}
@include media-breakpoint-down(md) {
  .cc.pricecard {
    .price {
      font-size: 1.6em;
      font-weight: 300;
    }
  }
}


@include media-breakpoint-down(sm) {
  .cc.pricecard {
    .price {
      font-size: 1.4em;
      font-weight: 300;
    }
  }
}

html.dark-mode {
  .cc.pricecard {
    .pricetitle {
      &.ind {
        color: $gray-400;
      }
    }

    .pricetitleicon {
      &.ind {
        color: $gray-400;
      }
    }
  }
}
</style>
