<template>
  <PageFrame>
    <PageTitle
      title="Agile Mentoring."
      subtitle="Don't be just an Agile Coach or Scrum Master, be the water."
    />
  </PageFrame>

  <PageContextBar title="Agile Mentoring" pricetag="€ Pay What You Want" icon="spa" icon-color="red" sticky-on-desktop :items="tableofcontents" />

  <PageFrame background="widestickerbomb1">
    <VisualCue color="red" image="sunset" image-right>
      <template v-slot:title>
        Being vs Becoming.
      </template>
      <template v-slot:text>
        Agile Development and Agility are established terms in todays information society. Still, sometimes it is more a wrongly worn label rather than serious progress<span class="d-none d-xl-inline"> towards Agility</span>.
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame no-spacing>
    <h2 class="display-4">Agility from any angle</h2>

    <p>
      Thousands of Agile Coaches and Scrum Masters do their certifications, read hundreds of books and probably continuously learn along their way. That is, they practically never reach a state of <strong>being agile</strong>, but more likely <strong>become agile</strong> more and more every day.
    </p>

    <p>
      As much as Agile Software Development was needed in early 2000's, as much Agile Mastery is needed nowadays.
    </p>

    <p>
      Agility, Scrum, XP and their underlying principles and practices have come a long way. And still, there is quite some way to go when it comes to transforming teams and organisations.
    </p>

    <h2 class="display-4">Strength of experience</h2>

    <p>
      We support you in your personal journey on becoming Agile by giving you insights, experiences and know-how from many different perspectives. Only a few Agilists experience Agile Engineering in both software and organisational context, live and learn as Developer, Product Owner, Scrum Master, Agile Coach and Change Agent. We did - and we still do.
    </p>

    <p>
      Whether it is in small or large scale, whether it is to introduce or advance, whether it is to individualise or automate - we contribute to your journey as a true companion and peer.
    </p>

    <p>
      It doesn't matter if you are a Scrum Master, Agile Coach, Product Owner or neither of those. We discuss and train you in the topics you are interested in, which among many are these:
    </p>
  </PageFrame>

  <PagePoster
    title="Agile Mindset"
    description="A mindset is not a skill that can be obtained, but a practical, every-day approach towards a philosophy. Progressing towards an agile mindset is like walking in the shoes of knowledge on the path of experience while breathing the philosophy of Agility."
    color="red"
  >
    <template v-slot:poster>
      Breathe<br />
      The Philosophy<br />
      Of Agility.
    </template>
  </PagePoster>

  <PageFrame>
    <p>
      Sometimes, there is no clear answer to every question or challenge. Nonetheless, there is always a way to move forward. Our extensive, long-term experience in Agile Development and Agility in organisations gives us the evidence and confidence to make you move forward.
    </p>

    <h2 class="display-4">
      Agility for all
    </h2>

    <p>
      Have no single doubt that we surely do not know everything, but still hell of a lot about Agile, Scrum and the entire philosophy behind it. We firmly believe that many Agilists out there want to become better in understanding and implementing Agile Development.
    </p>

    <p>
      This is why we do not charge a fixed fee for our mentoring, but let you decide what you are willing or able to pay. Our goal is not to only assist the ones who can afford a world-class mentoring. Our mission is to assist everyone who is truly willing to improve themselves to become more Agile.
    </p>

    <PriceCard title="Agile Mentoring" companyprice="up to you" personprice="up to you" />

    <ContactAction
      teaser="No certificate or meetup will give you true mentoring."
      subtext="But a master with many years of experience will."
      cta="Let's become more agile"
    />
  </PageFrame>

  <PageLabels title="Our areas of expertise in Agile Mentoring" :labels="labels" />
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import PagePoster from "@/components/PagePoster.vue";
import PriceCard from "@/components/PriceCard.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactAction from "@/components/ContactAction.vue";
import PageLabels from "@/components/PageLabels.vue";

export default {
  name: "AgileMentoringPage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    PagePoster,
    PriceCard,
    VisualCue,
    ContactAction,
    PageLabels
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "arrow-right",
          iconOnRight: true,
          title: "Executive Coaching",
          route: "executivecoaching"
        }
      ],
      labels: [
        { name: "Agile Mindset" },
        { name: "Product Ownership" },
        { name: "Servant Leadership" },
        { name: "Holocracy" },
        { name: "Heterarchies" },
        { name: "Continuous Delivery" },
        { name: "TDD, BDD, ATDD" },
        { name: "Agile Testing" },
        { name: "Pair/Mob Programming" },
        { name: "Coding Dojos" },
        { name: "Cross-Functional Teams" },
        { name: "Lean Startup" },
        { name: "Retrospectives" },
        { name: "Agile Games" },
        { name: "Kanban & Kaizen" }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
