<template>
  <PageFrame>
    <PageTitle
      title="Leadership Coaching."
      subtitle="Listen&nbsp;without&nbsp;ears, speak&nbsp;without&nbsp;words, feel&nbsp;without&nbsp;fears, lead&nbsp;without&nbsp;forces."
    />
  </PageFrame>

  <PageContextBar title="Leadership Coaching" pricetag="€ 2.000,- / day" icon="star" icon-color="turquoise" sticky-on-desktop :items="tableofcontents" />

  <PageFrame background="widestickerbomb1">
    <VisualCue color="turquoise" title="Sharing Goals and Time." image="lake-view">
      <template v-slot:text>
        It all starts with such a simple, yet important foundation: The abilitiy to share goals and time. Sounds easy? Well, the magic is to connect goals to visions and time to compassion.
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame no-spacing>
    <h2 class="display-4">
      Leading means caring
    </h2>

    <p>
      Leadership is a practice that is a lot more concerned with the abilities of yourself rather than with the people led. There is so much advice on leadership out there nowadays. Entire shelves of books, thousands of seminars and workshops, millions of social media accounts exist to "guide" and "help" you on your leadership journey.
    </p>

    <p>
      However, here is the thing: leadership does not happen in ones mind. Leadership happens when interacting with people. Like real people, in real life.
    </p>

    <h2 class="display-4">
      Mastering modern leadership
    </h2>

    <p>
      We strongly and firmly believe that a guided, practice-driven and individual type of learning is most effective in obtaining a solid factual and mental skillset of modern leadership.
    </p>
    <p>
      That is what we do in our leadership coaching. A fundamental approach in our guided coaching is to respect and incorporate the individual situation of our coachees. Simply because we know very well that leadership in not only a practice to be aquired through knowledge.
      In fact it is an assignment legitimated by your environment or organisation to lead. It is how well this assignment is turned into favorable behaviour and tangible decisions that enables your companions to reach their goals.
    </p>
  </PageFrame>

  <PagePoster
    title="Servant Leadership"
    description="A fundamental principle in social systems is that leaders are not made by themselves but rather by the organisation they are part of. Leadership is how legitimated authority is applied for the good of everyone."
    color="turquoise"
  >
    <template v-slot:poster>
      People<br />
      Make<br />
      Leaders.
    </template>
  </PagePoster>

  <PageFrame>
    <p>
      Equipped with more than a decade of practical experience and deep knowledge of social systems, human psychoanalysis and systemic thinking, we do not care about knowledge and practices as much as we do care about your unique way of leadership.
    </p>

    <h2 class="display-4">
      Your leadership is unique and different
    </h2>

    <p>
      The one and only most important factor of leadership is authenticity. We respect, value and embrace your character, spirit and soul in every single moment of our coaching.
    </p>

    <PriceCard title="Leadership Coaching" companyprice="€ 2.000,-" personprice="€ 2.000,-" />

    <ContactAction
      teaser="The opposite of leading is not following, it is ignorance."
      subtext="Let's shape your unique way of leadership. Now."
      cta="Let's share goals &amp; time"
    />
  </PageFrame>

  <PageLabels title="Our areas of expertise in Leadership Coaching" :labels="labels" />
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import PagePoster from "@/components/PagePoster.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactAction from "@/components/ContactAction.vue";
import PriceCard from "@/components/PriceCard.vue";
import PageLabels from "@/components/PageLabels.vue";

export default {
  name: "LeadershipCoaching",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    PagePoster,
    VisualCue,
    ContactAction,
    PriceCard,
    PageLabels
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "arrow-right",
          iconOnRight: true,
          title: "Digital Product Advisory",
          route: "digitalproductadvisory"
        }
      ],
      labels: [
        { name: "Management 3.0 + 4.0" },
        { name: "Servant Leadership" },
        { name: "Kaizen & Kaikaku" },
        { name: "Second Order Cybernetics" },
        { name: "Rhetorical Training" },
        { name: "Social Systems" },
        { name: "Constructivism" },
        { name: "Storytelling" }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
