import window from "window-or-global";
import releases from "../../public/releasenotes.json";

export default {
  withVue(app) {
    if (!app) return app;

    app.config.globalProperties.$cclabs = this;

    app.greetTheWorld = this.greetTheWorld;

    app.useTracker = (plugin, router) => {
      const isHostedOnDomain = this.isHostedOnDomain();
      if (!isHostedOnDomain) {
        console.warn("Running on unauthorised domain, disabling web tracking");
      }
      return app.useIf(this.isHostedOnDomain(), plugin, this.trackingOptions(router));
    };

    app.useIf = (condition, plugin, options) => {
      return condition ? app.use(plugin, options) : app;
    };

    return app;
  },
  greetTheWorld() {
    const latest = releases[0];

    console.info("Welcome to thecclabs.com!");
    console.info(`Running «${latest.name}» (v${latest.version})`);

    return this;
  },
  isHostedOnDomain() {
    return !!this.hostname.match(/thecclabs\.com/i);
  },
  isHostedOnNextSubdomain() {
    return !!this.hostname.match(/next\.thecclabs\.com/i);
  },
  trackingOptions(router) {
    return {
      router: router,
      host: "https://ia.thecclabs.com",
      siteId: 3 + this.isHostedOnNextSubdomain(),
      enableLinkTracking: true,
      disableCookies: true,
      domains: ["*.thecclabs.com"]
    };
  },
  utils: {
    reverse(str) {
      return (str === '') ? '' : this.reverse(str.substr(1)) + str.charAt(0);
    },
    tagspray(str, cls) {
      var parts = (str).split("");
      parts.forEach((part, i) => {
        if (part === " ") part = "&nbsp;";
        const tagstart = cls ? "<span class='" + cls + "'>" : "<span>";
        parts[i] = tagstart + part + "</span>";
      });

      return parts.join("");
    },
    compose() {
      const t = this.reverse(":otliam") + this.reverse("@olleh") + this.reverse("moc.sbalcceht");
      const s = "?subject=[HEY!] Get back to me asap :-)";
      const b = "&body=Hi CCLABS,%0D%0A%0D%0AJust get back to me asap, I am looking forward to it!%0D%0A%0D%0AThank you!%0D%0ABest regards,%0D%0A";

      return t + s + b;
    },
    goTo(href) {
      window.location.href = href;
    }
  },
  props() {
    return {
      phone: this.utils.reverse(" 251 94+") + this.utils.reverse("59673013")
    }
  },
  hostname: window.location.hostname || ""
};
