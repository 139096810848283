<template>
  <div id="releaselist" class="cc releaselist bs card accordion">
    <ul class="cc releaseslistitems bs list-group" aria-label="Releases" v-if="releases && releases.length > 0">
      <li class="bs list-group-item" v-for="(release, index) in releases" :key="release.version">

        <div class="cc list-group-item-bar bs d-flex align-items-center">
          <a class="cc releasename bs mr-auto" data-toggle="collapse" :href="'#release' + index" role="button" aria-label="Toggle details for this release" :aria-expanded="firstExpanded && index < 1" :aria-controls="'#release' + index">
            {{ release.name }}
          </a>
          <i class="cc releaseqoute bs d-none d-md-inline">
            &laquo;&nbsp;{{ release.quote }}&nbsp;&raquo;
          </i>
          <div class="bs d-flex align-items-center">

            <router-link :to="{ name: 'release', params: { version: release.version } }">
              <span class="cc badge-version bs badge badge-light" :class="release.candidate ? 'bg-warning' : ('badge-o' + ((index % 5) + 1))">
                {{ release.version }}
              </span>
            </router-link>

            <a class="bs collapse-chevron" data-toggle="collapse" :href="'#release' + index" role="button" aria-label="Toggle details for this release" :aria-expanded="firstExpanded && index < 1" :aria-controls="'#release' + index">
              <fa icon="chevron-up" class="bs d-block"></fa>
            </a>
          </div>
        </div>

        <article class="bs collapse" :class="firstExpanded && index < 1 ? 'show' : ''" :id="'release' + index" data-parent="#releaselist" :aria-label="'Details for release ' + release.version">
          <div class="cc list-group-item-preview">
            <ReleaseCandidateWarning :release="release" v-if="release.candidate" />
            <ReleaseNotesAndFeatures :release="release" />
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import ReleaseCandidateWarning from "@/components/ReleaseCandidateWarning.vue";
import ReleaseNotesAndFeatures from "@/components/ReleaseNotesAndFeatures.vue";

export default {
  name: "ReleasesList",
  components: {
    ReleaseCandidateWarning,
    ReleaseNotesAndFeatures
  },
  data() {
    return {
      releases: null,
      loading: true,
      loaderror: false,
    };
  },
  mounted() {
    axios
      .get("releasenotes.json", { responseType: 'json' })
      .then(response => {
        const releases = response.data;
        this.releases = releases.filter(x => !x.draft);
      })
      .catch(() => this.loaderror = true)
      .finally(() => this.loading = false);
  }
};
</script>

<style lang="scss">
.cc.releaseslistitems {
  .list-group-item {
    padding: 15px 0px;
  }

  .list-group-item-bar {
    text-decoration: none;
  }

  .list-group-item-bar:hover {
    color: $black;
  }

  .collapse-chevron {
    text-decoration: none;
  }

  [aria-expanded="false"].collapse-chevron svg {
    transform: rotate(180deg);
    transition: 0.2s;
  }

  [aria-expanded="true"].collapse-chevron svg {
    transform: rotate(0deg);
    transition: 0.2s;
  }

  .list-group-item-preview {
    margin-top: 15px;
    border-top: 1px solid $gray-400;
  }

  .releasename {
    text-decoration: none;
  }

  .releaseqoute {
    font-size: 0.8em;
    margin-right: 20px;
  }

  .badge-version {
    width: 60px;
    font-weight: normal;
    margin-right: 20px;
  }

  .badge-o1 {
    background-color: #dfdae5;
  }

  .badge-o2 {
    background-color: #efe5da;
  }

  .badge-o3 {
    background-color: #e5dfda;
  }

  .badge-o4 {
    background-color: #dae5e7;
  }

  .badge-o5 {
    background-color: #e5dae7;
  }
}

html.dark-mode {
  .cc.releaseslistitems {
    .list-group-item-bar:hover {
      color: $white;
    }

    .list-group-item-preview {
      border-top: 1px solid $black;
    }
  }
}

</style>
