<template>
  <PageFrame>
    <PageTitle
      title="Legal Notes."
      subtitle="The things you never wanted to ask and even did not think about."
    />

    <p>
      It seems you are really interested about us and the formal facts of ours.<br/>
      Here are our legal notes. Enjoy.
    </p>
  </PageFrame>

  <PageFrame class="cc imprint">
    <div class="cc typewriter bs container">
      <div class="cc typerow bs d-flex flex-wrap" style="padding-top:20px;">
        <span class="cc inlay"><TextLogo minimal/></span>
      </div>
      <div class="cc typerow bs d-flex flex-wrap" style="padding-top:10px;" v-html="$cclabs.utils.tagspray('Studio f&uuml;r Produkt- und Software-Entwicklungsberatung')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray(' ')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray('Offered Services:')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray('IT-Management-Consulting &amp; Führungskräfte-Entwicklung')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray(' ')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray('Official Contact:')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray(person)"></div>
      <a class="cc typerow bs d-flex flex-wrap" :href="composetarget" @click="compose" v-html="$cclabs.utils.tagspray('E-Mail:  ' + liam)" />
      <a class="cc typerow bs d-flex flex-wrap" :href="ttarget" @click="compose" v-html="$cclabs.utils.tagspray('Phone:   ' + phone)"></a>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray(' ')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray('Postal Address:')"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray(poststreet)"></div>
      <div class="cc typerow bs d-flex flex-wrap" v-html="$cclabs.utils.tagspray(postzipcity)"></div>
      <div class="cc typerow bs d-flex flex-wrap" style="padding-bottom:25px;"  v-html="$cclabs.utils.tagspray(postcountry)"></div>
    </div>
  </PageFrame>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import TextLogo from "@/components/TextLogo.vue";

export default {
  name: "Impressum",
  data() {
    const u = this.$cclabs.utils;

    return {
      liam: u.reverse("@liam") + u.reverse("moc.sbalcceht"),
      person: u.reverse("reklI") + " " + u.reverse("ayakniteC"),
      vat: "161/209/10330",
      phone: u.reverse(" 251 94+") + u.reverse("59673013"),
      poststreet: "Beiselestrasse 27b",
      postzipcity: "82327 Tutzing",
      postcountry: "Germany",
      composetarget: "",
      ttarget: ""
    }
  },
  components: {
    PageTitle,
    PageFrame,
    TextLogo
  },
  methods: {
    compose() {
      const u = this.$cclabs.utils;
      const p = this.$cclabs.props();

      this.composetarget = u.compose();
      this.ttarget = "tel:" + p.phone;
    },

  }
};
</script>

<style lang="scss">
.cc.imprint {
  .typewriter {
    font-family: "Menlo", "Consolas", monospace;
    font-size: 0.8em;
    font-weight: light;
    background: $light;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $gray-600;
  }

  .typerow:first-child {
    padding-top: 1em;
  }

  a.typerow {
    font-size: 1em;
    font-weight: normal;
    text-decoration: none;
  }

  a.typerow:hover {
    color: $primary;
  }

  .typerow {
    padding: 0em 1em;

    span.wrap, span.wrap span {
      padding: 0px;
      margin: 0px;
      border-bottom: 0px;
    }

    span.inlay {
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: 1px solid $gray-300;
    }

    span.inlay span {
      padding: 0px;
      margin: 0px;
      border-bottom: 0px;
    }

    span {
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid $gray-300;
    }

    span:last-child {
      flex: 1;
    }
  }
}

@include media-breakpoint-down(md) {
  .cc.imprint {
    .typewriter {
      font-family: "Menlo", "Consolas", monospace;
      font-size: 0.5em;
    }
  }
}

html.dark-mode {
  .cc.imprint {
    .typewriter {
      background: $gray-900;
      color: $gray-600;
    }

    a.typerow {
      font-size: 1em;
    }

    a.typerow:hover {
      color: $primary;
    }

    .typerow {
      span.inlay {
        border-bottom: 1px solid $gray-700;
      }

      span.inlay span {
        border-bottom: 0px;
      }

      span {
        border-bottom: 1px solid $gray-700;
      }
    }
  }
}
</style>
