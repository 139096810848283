<template>
  <PageFrame>
    <PageTitle
      title="Modern Management."
      subtitle="From employees to companions, and managers to mentors."
    />
  </PageFrame>

  <PageContextBar title="Modern Management" pricetag="€ 4.000,- / day" icon="compass" icon-color="gold" sticky-on-desktop :items="tableofcontents" />

  <PageFrame background="widestickerbomb3">
    <VisualCue color="gold" title="People over Process." image="desk-keyboard">
      <template v-slot:text>
        People over Process is an agile paradigm of cooperative <span class="d-none d-xl-inline">and inclusive </span>management. Although people are inherently complex, their management must not be complex<span class="d-none d-xl-inline"> as well</span>.
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame no-spacing>
    <h2 class="display-4">
      Complexity&nbsp;is&nbsp;easier than order or chaos
    </h2>

    <p>
      Modern management is all about dealing with complexity. And surprisingly enough, dealing with complexity is rather easier than complex. Well, we know how to make it easier.
    </p>

    <p>
      Often times, the <strong>people factor</strong> is not very much seen or dealt with in organisations. We got the skills to bring the people factor back in balance while still paying attention to all other important factors of a successful business.
    </p>

    <p>
      We offer and serve you our battle-tested experience in dealing with dynamic, fast-paced and fast-growing working environments.
      Expect no less than decades of experience in growing, fluent and dynamic organisations and a strong conceptual knowledge of social systems.
    </p>

    <p>
      That is why we adapt our skills to your individuality and situation. In the end, the biggest success factor is not all the methods and practices, but just simply you.
    </p>

    <h2 class="display-4">
      Know-How is useless if it&nbsp;doesn't fit you
    </h2>

    <p>
      Yes, we know a lot about modern management, but the most important question we quest ourselves willingly is what fits you and your needs.
    </p>
  </PageFrame>

  <PagePoster
    title="Management 3.0 + 4.0"
    description="We are no longer operating within the information age, we are progressing into a neodigitalised ecosphere. This requires us to shift our paradigms and practices in management of people and organisations."
    color="gold"
  >
    <template v-slot:poster>
      Neodigital<br />
      Ecosphere<br />
      Now.
    </template>
  </PagePoster>

  <PageFrame>
    <p>
      All of above topics and even more is what we do since years, and that is why we do not put our attention only to those best practices, but instead focus on your unique and individual way of organisation.
    </p>

    <PriceCard title="Modern Management" companyprice="€ 4.000,-" personprice="€ 2.000,-" />

    <ContactAction
      teaser="It's better to shape yourself than getting shaped."
      subtext="Your organisation and mission deserves a digital future."
      cta="Let's shape it"
    />
  </PageFrame>

  <PageLabels title="Our areas of expertise in Modern Management" :labels="labels" />
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import PagePoster from "@/components/PagePoster.vue";
import PriceCard from "@/components/PriceCard.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactAction from "@/components/ContactAction.vue";
import PageLabels from "@/components/PageLabels.vue";

export default {
  name: "ModernManagementPage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    PagePoster,
    PriceCard,
    VisualCue,
    ContactAction,
    PageLabels
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "arrow-right",
          iconOnRight: true,
          title: "Agile Mentoring",
          route: "agilementoring"
        }
      ],
      labels: [
        { name: "Organisational Development" },
        { name: "Management 3.0 + 4.0" },
        { name: "OKRs" },
        { name: "Delegated Strategy" },
        { name: "Holocracy" },
        { name: "Heterarchies" },
        { name: "Cross-Functional Teams" },
        { name: "Agile Development" },
        { name: "Scrum" },
        { name: "Second Order Cybernetics" },
        { name: "Systemic Consent" },
        { name: "Kanban & Kaizen" },
        { name: "New Work" },
        { name: "Workation" },
        { name: "Liberating Structures" }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
