<template>
  <div class="cc releasecandidatewarning" role="alert">
    <div class="bs d-flex justify-content-between">
      <h5>Release Preview!</h5>
    </div>
    <p>This private preview release of our website is just for testing &amp; evaluation purposes. You do see this message because you are member of <TextLogo lab="R3" />.</p>
  </div>
</template>

<script>
import TextLogo from "@/components/TextLogo.vue";

export default {
  name: "ReleaseCandidateWarning",
  components: {
    TextLogo
  }
};
</script>

<style lang="scss">
.cc.releasecandidatewarning {
  padding: 20px;
  background-color: $warning;

  p {
    margin-bottom: 0px;
  }
}

html.dark-mode {
  .cc.releasecandidatewarning {
    background-color: $warning;

    p, h5 {
      color: $dark;
    }
  }
}
</style>
