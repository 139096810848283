<template>
  <section class="cc labels" aria-label="Labels">
    <div class="bs container" v-if="labels && labels.length > 0">
      <PageCaption :text="title" small />

      <ul class="bs list-inline">
        <li v-for="labels in labels" :key="labels.name" class="bs list-inline-item">
          <div class="cc label bs d-flex align-items-top">
            {{ labels.name }}
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import PageCaption from "@/components/PageCaption.vue";

export default {
  name: "PageLabels",
  components: {
    PageCaption
  },
  props: {
    title: String,
    labels: Object
  }
};
</script>

<style lang="scss">
.cc.labels {
  border-top: 1px solid $gray-200;
  margin-bottom: 40px;
  padding-top: 40px;

  .label {
    font-size: 0.6em;
    margin-top: 10px;
    padding: 10px;
    background-color: $gray-200;
  }
}

html.dark-mode {
  .cc.labels {
    border-color: $gray-600;

    .label {
      background-color: $gray-900;
    }
  }
}
</style>
