<template>
  <PageFrame>
    <PageTitle
      title="Backstage."
      subtitle="Welcome to our backstage area, the home of creativity and compassion."
    />
  </PageFrame>

  <PageContextBar title="Backstage" sticky-on-desktop :items="tableofcontents" />

  <PageFrame>
    <p class="cc para">
      It takes a great level of curiosity to reach to this point.
      Such an explorative mind deserves to know more about this website.
    </p>

    <h3 class="cc releases bs display-4">Releases</h3>
    <ReleasesList />
  </PageFrame>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";

import ReleasesList from "@/components/ReleasesList.vue";

export default {
  name: "BackstagePage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    ReleasesList
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "gift",
          title: "Releases",
          route: "backstage"
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.cc.releases {
  margin-top: 30px;
  margin-bottom: 0px;
}
.cc.para {
  margin-top: 20px;
}
</style>
