<template>
  <footer class="cc footer" aria-label="Website general information">
    <div class="bs container">
      <div class="bs row align-items-center justify-content-between">
        <div class="bs col-6 col-lg-8">
          <p>
            <TextLogo minimal dark @click="switchColorScheme" />
          </p>
        </div>

        <div class="bs col-6 col-lg-4 text-right">
          <p>
            <fa icon="copyright" class="bs small"></fa> {{ year }}
          </p>
        </div>
      </div>
      <div class="bs row align-items-center justify-content-between">
        <div class="bs col-6 col-lg-8">
          <p>
            <span class="bs text-nowrap">{{ claim }}</span>
          </p>
        </div>

        <div class="bs col-6 col-lg-4 text-right">
          <p>
            {{ location }}
          </p>
        </div>
      </div>
    </div>

    <NavMeta />
  </footer>
</template>

<script>
import NavMeta from "@/components/NavMeta.vue";
import TextLogo from "@/components/TextLogo.vue";

export default {
  name: "WebsiteFooter",
  components: {
    NavMeta,
    TextLogo
  },
  data() {
    const date = new Date();

    return {
      claim: "We Reinvent Work.",
      year: "2011 - " + date.getFullYear(),
      location: "Tutzing, Germany."
    }
  },
  methods: {
    switchColorScheme() {
      const thecclabs = document.getElementById("thecclabs");
      const isDarkMode = thecclabs.classList.contains("dark-mode");

      if (isDarkMode) {
        thecclabs.classList.add("light-mode");
        thecclabs.classList.remove("dark-mode");
      } else {
        thecclabs.classList.add("dark-mode");
        thecclabs.classList.remove("light-mode");
      }
    }
  }
};
</script>

<style lang="scss">

$cc-footer-bg: $dark;
$cc-footer-bg-dark: $black;

$cc-footer-color: $gray-400;
$cc-footer-color-dark: $gray-400;

.cc.footer {
  background-color: $cc-footer-bg;
  padding-top: 20px;

  .container {
    padding-bottom: 20px;
  }

  p {
    font-size: 12pt;
    color: $cc-footer-color;
    padding: 0px;
    margin: 0px;
  }
}

html.dark-mode {
  .cc.footer {
    background-color: $cc-footer-bg-dark;

    p {
      color: $cc-footer-color-dark;
    }
  }
}

</style>
