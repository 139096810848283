<template>
  <section class="cc pageframe" :class="_flags">
    <div class="bs container d-flex align-items-top">
      <div class="bs flex-fill"><slot></slot></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageFrame",
  props: {
    striped: Boolean,
    spacing: Boolean,
    noSpacing: Boolean,
    color: {
      type: String,
      default: "default"
    },
    background: {
      type: String,
      default: "none"
    }
  },
  computed: {
    _flags() {
      var flags = "";
      if (this.spacing) flags += "spacing ";
      if (this.noSpacing) flags += "no-spacing ";
      if (this.striped) flags += `stripe ${this.color} `;
      if (this.background != "none") flags += `background ${this.background} ${this.color} `;

      return flags;
    }
  }
};
</script>

<style lang="scss">
html {
  --stripe-light-color: #{$gray-100};
  --stripe-dark-color: #{$gray-900};
}

.cc.pageframe {
  margin-bottom: 40px;
}

.cc.pageframe.no-spacing {
  margin-bottom: 0px;
}
.cc.pageframe:first-of-type {
  margin-bottom: 0px;
}

.cc.pageframe.spacing:first-of-type {
  margin-bottom: 40px;
}

.stickerbomb {
  background-image: url(../assets/img/stickerbomb-bg1-1280x360.jpg);
  box-shadow: inset 0 0 0 50vw rgba($white,0.9);
}

.widestickerbomb3 {
  background-image: url(../assets/img/stickerbomb-bg3-1920x480.jpg);
  box-shadow: inset 0 0 0 50vw rgba($white,0.9);
}

.widestickerbomb2 {
  background-image: url(../assets/img/stickerbomb-bg2-1920x480.jpg);
  box-shadow: inset 0 0 0 50vw rgba($white,0.9);
}

.widestickerbomb1 {
  background-image: url(../assets/img/stickerbomb-bg1-1920x480.jpg);
  box-shadow: inset 0 0 0 50vw rgba($white,0.9);
}

.cc.background {
  width: 100%;
  height: auto;

  &.turquoise { box-shadow: inset 0 0 0 50vw rgba($color-turquoise-light, 0.9) };
  &.lilac { box-shadow: inset 0 0 0 50vw rgba($color-lilac-light, 0.9) };
  &.gold { box-shadow: inset 0 0 0 50vw rgba($color-gold-light, 0.9) };
  &.red { box-shadow: inset 0 0 0 50vw rgba($color-red-light, 0.9) };
  &.blue { box-shadow: inset 0 0 0 50vw rgba($color-blue-light, 0.9) };
  &.green { box-shadow: inset 0 0 0 50vw rgba($color-green-light, 0.9) };
}

.cc.stripe {
  background-color: var(--stripe-light-color);
}

.cc.stripe.turquoise { background-color: $color-turquoise-light };
.cc.stripe.lilac { background-color: $color-lilac-light };
.cc.stripe.gold { background-color: $color-gold-light };
.cc.stripe.red { background-color: $color-red-light };
.cc.stripe.blue { background-color: $color-blue-light };
.cc.stripe.green { background-color: $color-green-light };

html.dark-mode {
  .cc.stripe {
    background-color: var(--stripe-dark-color);
  }

  .stickerbomb {
    box-shadow: inset 0 0 0 50vw rgba($black,0.9);
  }

  .widestickerbomb3 {
    box-shadow: inset 0 0 0 50vw rgba($black,0.9);
  }

  .widestickerbomb2 {
    box-shadow: inset 0 0 0 50vw rgba($black,0.9);
  }

  .widestickerbomb1 {
    box-shadow: inset 0 0 0 50vw rgba($black,0.9);
  }

  .cc.stripe.turquoise { background-color: $color-turquoise-dark };
  .cc.stripe.lilac { background-color: $color-lilac-dark };
  .cc.stripe.gold { background-color: $color-gold-dark };
  .cc.stripe.red { background-color: $color-red-dark };
  .cc.stripe.blue { background-color: $color-blue-dark };
  .cc.stripe.green { background-color: $color-green-dark };

  .cc.background.turquoise { box-shadow: inset 0 0 0 50vw rgba($color-turquoise-dark, 0.9) };
  .cc.background.lilac { box-shadow: inset 0 0 0 50vw rgba($color-lilac-dark, 0.9) };
  .cc.background.gold { box-shadow: inset 0 0 0 50vw rgba($color-gold-dark, 0.9) };
  .cc.background.red { box-shadow: inset 0 0 0 50vw rgba($color-red-dark, 0.9) };
  .cc.background.blue { box-shadow: inset 0 0 0 50vw rgba($color-blue-dark, 0.9) };
  .cc.background.green { box-shadow: inset 0 0 0 50vw rgba($color-green-dark, 0.9) };
}

</style>
