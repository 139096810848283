<template>
  <div class="cc metanav">
    <NavBeam small flip expanded name="Meta" :items="meta" beam="8px" bar="60px" />
  </div>
</template>

<script>
import NavBeam from "@/components/NavBeam.vue";

export default {
  name: "NavMeta",
  components: {
    NavBeam
  },
  data() {
    return {
      meta: [
        {
          title: "Legal Notes",
          route: "legalnotes",
        },
        {
          title: "Backstage",
          route: "backstage",
        }
      ]
    }
  }
};
</script>

<style lang="scss">

$cc-metanav-bg: $dark;
$cc-metanav-bg-dark: $black;

$cc-metanav-border-color: $dark;
$cc-metanav-border-color-dark: $dark;

$cc-metanav-color: $gray-400;
$cc-metanav-color-dark: $gray-400;

$cc-metanav-beam-color: $black;
$cc-metanav-beam-color-dark: $dark;

$cc-metanav-beam-color-hover: $light;
$cc-metanav-beam-color-hover-dark: $secondary;

.cc.metanav  {
  .flip.navbar {
    border-top-color: $cc-metanav-border-color;
    background-color: $cc-metanav-bg;
    color: $cc-metanav-color;

    .nav-item, .navbar-brand {
      border-bottom-color: $cc-metanav-beam-color;
    }

    .nav-item:hover {
      border-bottom-color: $cc-metanav-beam-color-hover;

      * {
        color: $cc-metanav-beam-color-hover;
      }
    }

    * {
      color: $cc-metanav-color;
    }

    .spacer {
      background-color: $cc-metanav-bg;
      border-bottom-color: $cc-metanav-beam-color;
    }
  }
}

html.dark-mode {
  .cc.metanav {
    .flip.navbar {
      border-top-color: $cc-metanav-border-color-dark;
      background-color: $cc-metanav-bg-dark;
      color: $cc-metanav-color-dark;

      .nav-item, .navbar-brand {
        border-bottom-color: $cc-metanav-beam-color-dark;
      }

      .nav-item:hover {
        border-bottom-color: $cc-metanav-beam-color-hover-dark;
      }

      * {
        color: $cc-metanav-color-dark;
      }

      .spacer {
        background-color: $cc-metanav-bg-dark;
        border-bottom-color: $cc-metanav-beam-color-dark;
      }
    }
  }
}

</style>
