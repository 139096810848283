<template>
  <PageFrame>
    <PageTitle
      title="Executive Coaching."
      subtitle="We equip you with the tools and skills for the future of work and digitalisation."
    />
  </PageFrame>

  <PageContextBar title="Executive Coaching" pricetag="€ On Request" icon="dna" icon-color="blue" sticky-on-desktop :items="tableofcontents" />

  <PageFrame background="widestickerbomb2">
    <VisualCue color="blue" title="Digital Age of New." image="lake-peer">
      <template v-slot:text>
        The world is envisioning a New Normal, New Work, New Leadership and many more. The real work is to make progress on visions rather than just talking about them.
      </template>
    </VisualCue>
  </PageFrame>

  <PageFrame no-spacing>
    <p>
      Being in a highly responsible, highly demanding and highly decisive position can be one of the most fulfilling things of life. It requires continuous dedication, strong discipline and an awful amount of luck to master and succeed being the head and heart of an enterprise.
    </p>

    <h2 class="display-4">
      Face the challenge
    </h2>

    <p>
      That is where the challenge starts. It is not the vision that is challenging, but the road to get there. Executives of all disciplines know this. Most often with big visions and challenges, it is simply not enough knowing them. Instead, it requires substantial courage and guidance to aim for those goals - day by day, in every step and way.
    </p>

    <p>
      This is what we offer all leaders and executives on their journey towards digitalisation, transformation and renewal in a radipdly moving and progressing society.
    </p>

    <h2 class="display-4">
      Face to face, shoulder to shoulder
    </h2>

    <p>
      In the past 15 years we worked in many different industries for and with top managers. As much as there are different human beings, you will find different leaders. We combine the expertise of decades working in the digital ecosphere with empathy on the responsibilities, uncertainties, adversities and challenges of pursuing goals as a top leader and manager.
    </p>
  </PageFrame>

  <PagePoster
    title="Discrete Digital Advisory"
    description="There is no guaranteed success. However, there can be guaranteed companionship. The most loyal and constructive companion is the one walking along with you in silence."
    color="blue"
  >
    <template v-slot:poster>
      Silently<br />
      Walking<br />
      Side By Side.
    </template>
  </PagePoster>

  <PageFrame>
    <p>
      This is why executives of enterprises, unicorns and start-ups are trusting us to be their discrete and deliberate companion, their sparring-partner and their advocate.
    </p>

    <p>
      Rest assured that we always invest in long-term, loyal, trustful and reliable relationships.
    </p>

    <PriceCard title="Executive Coaching" companyprice="on request" personprice="on request" />

    <ContactAction
      teaser="You will be always on your own, but not alone."
      subtext="Appreciating the journey is key to keep the goal in focus."
      cta="Let's walk side by side"
    />
  </PageFrame>

  <PageLabels title="Our areas of expertise in Executive Coaching" :labels="labels" />
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import PagePoster from "@/components/PagePoster.vue";
import PriceCard from "@/components/PriceCard.vue";
import VisualCue from "@/components/VisualCue.vue";
import ContactAction from "@/components/ContactAction.vue";
import PageLabels from "@/components/PageLabels.vue";

export default {
  name: "ExecutiveCoachingPage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    PagePoster,
    PriceCard,
    VisualCue,
    ContactAction,
    PageLabels
  },
  data() {
    return {
      tableofcontents: [
        {
          icon: "arrow-right",
          iconOnRight: true,
          title: "Pro-Bono Mentoring",
          route: "probonomentoring"
        }
      ],
      labels: [
        { name: "Discrete Digital Advisory" },
        { name: "IT & Software Due Diligence" },
        { name: "Agile Development" },
        { name: "Scrum" },
        { name: "Crisis Management" },
        { name: "Organisational Development" },
        { name: "Rhetorical Training" },
        { name: "Kaizen & Kaikaku" },
        { name: "Agile Mindset" },
        { name: "Burn-Out Prevention" },
        { name: "Digital Product Assessment" },
        { name: "Resilience Management" }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
