<template>
  <span data-gadget="TextLogo" class="d-inline-flex align-items-center cc"
    :class="(minimal ? 'minimal' : (mono ? 'mono' : '')) + (dark ? ' dark' : '')"
  >
    <span class="seesee">CC</span>
    <span class="dash">|</span>
    <span class="labs">{{ lab }}</span>
  </span>
</template>

<script>
export default {
  name: "TextLogo",
  props: {
    lab: {
      type: String,
      default: "LABS"
    },
    minimal: Boolean,
    mono: Boolean,
    dark: Boolean
  }
};
</script>

<style lang="scss">

$cc-textlogo-bg: $light;
$cc-textlogo-bg-dark: $black;

$cc-textlogo-color: $dark;
$cc-textlogo-color-dark: $white;

[data-gadget="TextLogo"].cc {
  max-height: 26pt;

  font-family: $font-type-light;
  font-weight: normal;

  user-select: none;
  background-color: $cc-textlogo-bg;
  padding: 4px;

  .seesee, .labs {
    font-family: $font-type-light;
    font-weight: normal;
    color: $cc-textlogo-color;
  }

  .dash {
    font-size: 1.8rem;
    font-weight: normal;
    color: $primary;
  }
}

[data-gadget="TextLogo"].cc.minimal {
  padding: 0px;

  .dash {
    font-size: 20pt;
    font-weight: normal;
  }
}

[data-gadget="TextLogo"].cc.mono {
  padding: 0px;

  font-family: "Menlo", "Consolas", monospace;
  font-weight: normal;

  .dash {
    font-size: 20pt;
    font-weight: normal;
  }
}

[data-gadget="TextLogo"].cc.dark {
  background-color: $dark;

  .seesee, .labs {
    color: $cc-textlogo-color-dark;
  }
}

html.dark-mode {
  [data-gadget="TextLogo"].cc {
    background-color: $cc-textlogo-bg-dark;

    .seesee, .labs {
      color: $cc-textlogo-color-dark;
    }
  }
}

</style>
