<template>
  <div
    class="cc pagecontextbar"
    :class="(fixed ? 'fixed-top' : (sticky ? 'sticky-top' : (stickyOnDesktop ? 'sticky-lg-top' : '')))"
    :style="`top:${offset};`"
  >
    <NavContext small name="pagecontextbar" :items="items" fin="1px" beam="1px" bar="52px">
      <template v-slot:title>
        <div
          class="cc title bs d-flex flex-grow-1 align-items-center justify-content-between justify-content-lg-start"
         >
          <div class="cc docicon" :class="iconColor"><fa :icon="icon"></fa></div>
          <h6 class="cc titleline bs small">{{ title }}</h6>

          <div class="cc pricetag bs small" v-if="pricetag">
            {{ pricetag }}
          </div>
          <div class="cc version bs small ml-auto" v-if="version">
            {{ version }}
          </div>
          <div class="cc dateline bs small" v-if="dateline">
            {{ dateline }}
          </div>
        </div>
      </template>
    </NavContext>
  </div>
</template>

<script>
import NavContext from "@/components/NavContext.vue";

export default {
  name: "PageContextBar",
  components: {
    NavContext
  },
  props: {
    icon: {
      type: String,
      default: "file-alt"
    },
    iconColor: {
      type: String,
      default: "default"
    },
    title: String,
    pricetag: String,
    dateline: String,
    version: String,
    fixed: Boolean,
    sticky: Boolean,
    stickyOnDesktop: Boolean,
    items: Object,
    offset: {
      type: String,
      default: "120px"
    }
  }
};
</script>

<style lang="scss">

$cc-pagenav-bg: $white;
$cc-pagenav-bg-dark: $dark;

$cc-pagenav-border-color: $gray-200;
$cc-pagenav-border-color-dark: $gray-600;

$cc-pagenav-color: $gray-600;
$cc-pagenav-color-dark: $gray-400;

$cc-pagenav-beam-color: $gray-200;
$cc-pagenav-beam-color-dark: $gray-600;

$cc-pagenav-beam-color-hover: $dark;
$cc-pagenav-beam-color-hover-dark: $light;

$cc-badge-version-bg: $light;
$cc-badge-version-bg-dark: $black;

.cc.pagecontextbar {
  .title {
    .docicon {
      position: relative;
      left: -22px;
      max-width: 18px;
    }

    .docicon.lilac * { color: $color-lilac; }
    .docicon.blue * { color: $color-blue; }
    .docicon.gold * { color: $color-gold; }
    .docicon.green * { color: $color-green; }
    .docicon.turquoise * { color: $color-turquoise; }
    .docicon.red * { color: $color-red; }

    .titleline {
      margin: 0px;
      margin-left: 0px;
      font-weight: 400;
      color: $cc-pagenav-color;
    }

    .pricetag {
      margin-left: 40px;
      margin-top: 2px;
      font-weight: 300;
    }

    .dateline, .version {
      margin-left: 25px;
      margin-right: 15px;
      margin-top: 2px;
      margin-top: 8px;
      font-weight: 300;
    }
  }

  .navbar {
    border-bottom-color: $cc-pagenav-border-color;
    background-color: $cc-pagenav-bg;
    color: $cc-pagenav-color;

    * {
      color: $cc-pagenav-color;
    }

    .spacer {
      background-color: $cc-pagenav-bg;
      border-top-color: $cc-pagenav-beam-color;
    }

    .nav-link span {
      font-weight: 300;
    }

    .itemicon * {
      color: $gray-500;
    }

    .nav-link:first-child {
      padding: 4px 15px 4px 8px;
    }

    .nav-link:hover {
      * {
        color: $gray-800;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .cc.pagecontextbar.sticky-lg-top {
    // this is a shim and can be removed once on bootstrap 5.0
    // https://getbootstrap.com/docs/5.0/helpers/position/#responsive-sticky-top
    position: sticky;
    z-index: $zindex-sticky;
  }
}


@include media-breakpoint-down(md) {
  .cc.pagecontextbar {
    .docicon { display: none; }
    .title {
      margin-left: 15px;
      margin-right: 15px;

      .titleline {
        font-weight: 300;
      }
    }

    .navbar-toggler {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .cc.pagecontextbar {
    display: none;
  }
}

html.dark-mode {
  .cc.pagecontextbar {
    .title {
      .docicon.lilac * { color: $color-lilac; }
      .docicon.blue * { color: $color-blue; }
      .docicon.gold * { color: $color-gold; }
      .docicon.green * { color: $color-green; }
      .docicon.turquoise * { color: $color-turquoise; }
      .docicon.red * { color: $color-red; }

      .titleline {
        color: $cc-pagenav-color-dark;
      }
    }

    .navbar {
      border-bottom-color: $cc-pagenav-border-color-dark;
      background-color: $cc-pagenav-bg-dark;
      color: $cc-pagenav-color-dark;


      .nav-item, .navbar-brand {
        border-top-color: $cc-pagenav-beam-color-dark;
      }

      .nav-item:hover {
        border-top-color: $cc-pagenav-beam-color-hover-dark;

        * {
          color: $gray-200;
        }
      }

      * {
        color: $cc-pagenav-color-dark;
      }

      .spacer {
        background-color: $cc-pagenav-bg-dark;
        border-top-color: $cc-pagenav-beam-color-dark;
      }
    }
  }
}

</style>
