<template>
  <PageFrame>
    <PageTitle
      title="We Reinvent Work."
      subtitle="We&nbsp;make&nbsp;you&nbsp;grow. As&nbsp;a&nbsp;human, as&nbsp;a&nbsp;leader, as&nbsp;a&nbsp;company."
    />
  </PageFrame>

  <PageFrame>
    <FeatureList
      :features="features"
    />
  </PageFrame>

  <PageQuote winner />

  <PageFrame>
    <ContactAction
      teaser="We do everything with full discretion &amp; dedication."
      subtext="Discover how we tailor the perfect fit for your needs and goals."
      cta="Let's grow"
      link="contact"
    />
  </PageFrame>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import FeatureList from "@/components/FeatureList.vue";
import ContactAction from "@/components/ContactAction.vue";
import PageQuote from "@/components/PageQuote.vue";

export default {
  name: "PortfolioPage",
  components: {
    PageTitle,
    PageFrame,
    FeatureList,
    ContactAction,
    PageQuote
  },
  data() {
    return {
      features: [
        {
          id: 1,
          icon: "star",
          color: "turquoise",
          link: "leadershipcoaching",
          title: "Leadership Coaching",
          claim: "We coach managers to recognise themselves as servant leaders and companions."
        },
        {
          id: 2,
          icon: "code",
          color: "lilac",
          link: "digitalproductadvisory",
          title: "Digital Product Advisory",
          claim: "We know the DNA of successful digital products: simplicity, reliability, continuity, emotionality."
        },
        {
          id: 3,
          icon: "compass",
          color: "gold",
          link: "modernmanagement",
          title: "Modern Management",
          claim: "We don't talk much about Agile, Lean or New Work. Because we are doing it since decades."
        },
        {
          id: 4,
          icon: "spa",
          color: "red",
          link: "agilementoring",
          title: "Agile Mentoring",
          claim: "We give you the sparring and mentoring from many years of agile work for great good."
        },
        {
          id: 5,
          icon: "dna",
          color: "blue",
          link: "executivecoaching",
          title: "Executive Coaching",
          claim: "CxO be like: me enthusiast, me improver, me innovator. We be like: Let's make it happen!"
        },
        {
          id: 6,
          icon: "bahai",
          color: "green",
          link: "probonomentoring",
          title: "Pro-Bono Mentoring",
          claim: "We support talented youngsters and socially disadvantaged to make their dreams come true."
        }
      ]
    }
  }
};
</script>

<style lang="scss">
</style>
