<template>
  <div id="app-canvas" class="min-vh-100">
    <WebsiteHeader />
    <router-view />
    <WebsiteFooter />
  </div>
</template>

<script>
import WebsiteHeader from "@/components/WebsiteHeader.vue";
import WebsiteFooter from "@/components/WebsiteFooter.vue";

export default {
  name: "cclabs-web",
  components: {
    WebsiteHeader,
    WebsiteFooter
  },
};
</script>
