<template>
  <header class="cc header">
    <NavBeam fixed name="Website" :items="website" bar="120px">
      <template v-slot:brand>
        <router-link to="/" style="margin-top:40px">
          <img src="../assets/img/cclabs-logo-black.svg" alt="CCLABS" role="presentation" width="129" height="35" />
        </router-link>
      </template>
    </NavBeam>
  </header>
</template>

<script>
import NavBeam from "@/components/NavBeam.vue";

export default {
  name: "WebsiteHeader",
  components: {
    NavBeam
  },
  data() {
    return {
      website: [
        {
          title: "Portfolio",
          route: "portfolio",
        },
        {
          title: "About",
          route: "about",
        },
        {
          title: "Contact",
          route: "contact",
        },
      ]
    }
  }
};
</script>

<style lang="scss">
.cc.header .navbeam .navbar {
  .navbar-brand {
    padding-top: 40px;
  }

  .nav-link {
    padding-top: 32px;
  }
}

.cc.header .navbar .navbar-brand img {
  content:url(../assets/img/cclabs-logo-black.svg);
}

@include media-breakpoint-down(sm) {
  .cc.header .navbeam .navbar {
    .navbar-brand {
      padding-top: 8px;
      padding-bottom: 12px;
    }

    .nav-link {
      padding-top: 0px;
    }
  }
}

@include media-breakpoint-down(md) {
  .cc.header .navbeam .navbar {
    .navbar-collapse {
      min-height: 100vh;
    }

    .nav-link {
      font-weight: 100;
      font-size: 3em;
      background: $light;
      text-align: center;
    }
  }

  html.dark-mode {
    .cc.header .navbeam .navbar {
      .nav-link {
        background: $gray-900;
      }
    }
  }
}

html.dark-mode {
  .cc.header .navbar .navbar-brand img {
    content:url(../assets/img/cclabs-logo-white.svg);
  }
}

@supports (-moz-appearance:none) {
  html.dark-mode {
    .cc.header .navbar .navbar-brand img {
      width: 0px;
      height: 0px;
      padding-top: 31px;
      padding-left: 120px;
      background:url(../assets/img/cclabs-logo-white.svg);
    }
  }
}

</style>
