<template>
  <section class="cc contactoptions">
    <div class="bs container">
      <div class="cc medialist bs row d-flex align-items-end justify-content-between">
        <div class="cc mediaitem bs col-12 col-md-6 col-lg-3">
          <div class="bs d-flex justify-content-start justify-content-md-end justify-content-lg-center align-items-center">
            <div>
              <a :href="ttarget" @click="compose" class="cc contacticon bs display-4"><fa icon="phone-square-alt"></fa></a>
            </div>
            <div>
              <h6>Phone</h6>
              <a :href="ttarget" @click="compose" class="cc contactid bs text-reset" v-html="phone"></a>
            </div>
          </div>
        </div>

        <div class="cc mediaitem bs col-12 col-md-6 col-lg-3 " @click="compose">
          <div class="bs d-flex justify-content-start justify-content-lg-center align-items-center">
            <div>
              <a :href="composetarget" @click="compose" class="cc contacticon bs display-4"><fa icon="envelope-square"></fa></a>
            </div>
            <div>
              <h6>E-Mail</h6>
              <a :href="composetarget" @click="compose" class="cc contactid bs text-reset" v-html="composedisplay"></a>
            </div>
          </div>
        </div>

        <div class="cc mediaitem bs col-12 col-md-6 col-lg-3" @click="goTo('https://www.linkedin.com/company/thecclabs')">
          <div class="bs d-flex justify-content-start  justify-content-md-end justify-content-lg-center align-items-center">
            <div>
              <a href="https://www.linkedin.com/company/thecclabs" class="cc contacticon bs display-4"><fa :icon="['fab', 'linkedin']"></fa></a>
            </div>
            <div>
              <h6>LinkedIn</h6>
              <a href="https://www.linkedin.com/company/thecclabs" class="cc contactid bs text-reset">@thecclabs</a>
            </div>
          </div>
        </div>

        <div class="cc mediaitem bs col-12 col-md-6 col-lg-3" @click="goTo('https://twitter.com/thecclabs')">
          <div class="bs d-flex justify-content-start justify-content-lg-center align-items-center">
            <div>
              <a href="https://twitter.com/thecclabs" class="cc contacticon bs display-4"><fa :icon="['fab', 'twitter']"></fa></a>
            </div>
            <div>
              <h6>Twitter</h6>
              <a href="https://twitter.com/thecclabs" class="cc contactid bs text-reset">@thecclabs</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactOptions",
  data() {
    var u = this.$cclabs.utils;

    return {
      composetarget: "",
      composedisplay: "",
      ttarget: "",
      phone: u.reverse(" 251 94+") + u.reverse("59673013")
    }
  },
  methods: {
    compose() {
      const u = this.$cclabs.utils;
      const p = this.$cclabs.props();

      this.composetarget = u.compose();
      this.ttarget = "tel:" + p.phone;
    },
    goTo(route) {
      this.$cclabs.utils.goTo(route);
    }
  },
  props: {
    dark: Boolean
  },
  mounted() {
    const u = this.$cclabs.utils;
    this.composedisplay = u.tagspray(u.reverse("sbalcceht@"));
  }
};
</script>

<style lang="scss">
.cc.contactoptions {
  margin-top: 20px;
  border-top: 4px solid $gray-300;

  .container {
    position: relative;
    top: -4px;
  }

  .contacticon {
    margin-right: 10px;
    text-decoration: none;
    color: $gray-600;
  }

  .contacticon:hover {
    text-decoration: none;
    color: $primary;
  }

  .contactid {
    font-size: 0.7em;
  }

  .contactid:hover {
    text-decoration: none;
  }

  .medialist {
    h6 {
      text-transform: uppercase;
      font-size: 0.8em;
      margin: 0px;
      color: $gray-600;
    }
  }

  .mediaitem {
    border-top: 4px solid $gray-300;

    padding-top: 10px;

    &:hover {
      cursor: pointer;
      border-top: 4px solid $gray-600;

      .contacticon {
        color: $black;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cc.contactoptions {
    .contactid {
      display: block;
      min-width: 150px;
    }

    .mediaitem {
      border-top: 0px;

      &:hover {
        border-top: 0px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cc.contactoptions {
    h3 {
      margin-top: 20px;
    }

    .contacticon {
      text-decoration: none;
      color: $gray-600;
    }

    .mediaitem {
      border-top: 0px;

      &:hover {
        border-top: 0px;
      }
    }

    .medialist {
      .col-12:first-child {
        border-top: 2px solid $gray-400;
      }

      .col-12 {
        padding-top: 10px;
        padding-bottom: 6px;
        border-bottom: 2px solid $gray-400;
      }

      .col-3 {
        padding-left: 20px;
      }

      .col-9 {
        padding-left: 20px;
      }
    }
  }

  html.dark-mode {
    .cc.contactoptions {
      .medialist {
        .col-12:first-child {
          border-top: 2px solid $gray-600;
        }

        .col-12 {
          border-bottom: 2px solid $gray-600;
        }
      }
    }
  }
}

html.dark-mode {
  .cc.contactoptions {
    border-top: 4px solid $gray-700;

    .contacticon {
      color: $gray-400;
    }

    .medialist {
      h6 {
        color: $gray-400;
      }
    }

    .mediaitem {
      border-top-color: $gray-700;
    }

    .mediaitem:hover {
      border-top-color: $light;

      .contacticon {
        color: $light;
      }
    }
  }
}
</style>
