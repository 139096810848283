<template>
  <small class="cc pagecaption bs text-uppercase" :class="small ? ' xs' : ''">
    {{ text }}
  </small>
</template>

<script>
export default {
  name: "PageCaption",
  props: {
    text: String,
    small: Boolean
  }
};
</script>

<style lang="scss">
.cc.pagecaption {
  font-weight: 300;

  &.xs { font-size: 0.7em; }
}

@include media-breakpoint-down(md) {
  .cc.pagecaption {
    font-size: 0.7em;

    &.xs { font-size: 0.6em; }
  }
}

@include media-breakpoint-down(sm) {
  .cc.pagecaption {
    font-size: 0.6em;

    &.xs { font-size: 0.5em; }
  }
}
</style>
