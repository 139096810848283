<template>
  <PageFrame v-if="release">
    <PageTitle
      :title="release.name + '.'"
      :subtitle="release.quote"
    />
  </PageFrame>

  <PageContextBar v-if="release" :title="release.name + '. ' + release.quote" icon="flag-checkered" :version="`Version ${release.version}`" sticky-on-desktop />

  <PageFrame v-if="release">
    <ReleaseCandidateWarning v-if="!isproduction && release.candidate" />

    <ReleaseNotesAndFeatures :release="release" feature-heading />

    <div class="cc releasequestions" v-if="questions && questions.length > 0">
      <h3 class="bs display-4">Questions</h3>

      <p class="bs card accordion">
        <ul class="bs list-group" aria-label="issues">
          <li class="bs list-group-item" v-for="issue in questions" :key="issue.number">
            <a class="bs d-flex align-items-center text-reset text-decoration-none">
              <span class="">
                {{ issue.title }}
              </span>
              <span class="bs ml-auto small issuenumber">
                #{{ issue.number }}
              </span>
            </a>
          </li>
        </ul>
      </p>
    </div>

    <div class="cc releaseenhancements" v-if="enhancements && enhancements.length > 0">
      <h3 class="bs display-4">Observations</h3>

      <div class="bs card accordion">
        <ul class="bs list-group" aria-label="issues">
          <li class="bs list-group-item" v-for="issue in enhancements" :key="issue.number">
            <a class="bs d-flex align-items-center text-reset text-decoration-none">
              <span class="">
                {{ issue.title }}
              </span>
              <span class="bs ml-auto small issuenumber">
                #{{ issue.number }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="cc releasefixes" v-if="fixes && fixes.length > 0">
      <h3 class="bs display-4">Fixes</h3>

      <div class="bs card accordion">
        <ul class="bs list-group" aria-label="issues">
          <li class="bs list-group-item" v-for="issue in fixes" :key="issue.number">
            <a class="bs d-flex align-items-center text-reset text-decoration-none">
              <span class="">
                {{ issue.title }}
              </span>
              <span class="bs ml-auto small issuenumber">
                #{{ issue.number }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </PageFrame>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageFrame from "@/components/PageFrame.vue";
import PageContextBar from "@/components/PageContextBar.vue";
import ReleaseCandidateWarning from "@/components/ReleaseCandidateWarning.vue";
import ReleaseNotesAndFeatures from "@/components/ReleaseNotesAndFeatures.vue";

import axios from "axios";
import semver from "semver";

export default {
  name: "ReleasePage",
  components: {
    PageTitle,
    PageFrame,
    PageContextBar,
    ReleaseCandidateWarning,
    ReleaseNotesAndFeatures
  },
  data() {
    return {
      id: "",
      release: null,
      fixes: null,
      questions: null,
      enhancements: null,
      loading: true,
      loaderror: false,
      isproduction: false
    };
  },
  mounted() {
    this.isproduction = this.$cclabs.isHostedOnDomain();

    this.id = new semver(this.$route.params.version);

    axios
      .get("/releasenotes.json", { responseType: 'json' })
      .then(response => {
        const releases = response.data;
        this.release = releases.find(x => semver.eq(this.id, x.version));

        return axios.get("/issues.json", { responseType: 'json' });
      })
      .then(response => {
        const newlabel = 'x-new-v' + this.release.version;
        const fixlabel = 'x-fix-v' + this.release.version;

        const issues = response.data;
        const releaseissues = issues.filter(x => x.labels.find(l => l.name == newlabel)).sort((a,b) => a.number - b.number);
        const releasefixes = issues.filter(x => x.labels.find(l => l.name == fixlabel)).sort((a,b) => a.number - b.number);

        this.fixes = releasefixes;
        this.questions = releaseissues.filter(x => x.labels.find(l => l.name == 'question'));
        this.enhancements = releaseissues.filter(x => x.labels.find(l => l.name == 'enhancement'));
      })
      .catch(() => this.loaderror = true)
      .finally(() => this.loading = false);
  }
};
</script>

<style lang="scss">

.cc.releasequestions, .cc.releaseenhancements, cc.releeasefixes {
  margin-bottom: 40px;

  .display-4 {
    margin-bottom: 0px;
  }
}

</style>
