<template>
  <section
    class="cc pagequote"
    aria-label="Core competence"
  >
    <div v-if="winner">
      <div class="bs container d-flex align-items-center justify-content-end justify-content-lg-between">
        <h3 class="bs display-4 d-none d-lg-block">
          We breathe and think<br/>
          for digital products<br/>
          and scaling start-ups.
        </h3>
        <blockquote class="bs blockquote text-right flex-fill">
            As champions of growth <br/>
            we know how to make <br/>
            <mark>everyone&nbsp;a&nbsp;winner.</mark>
        </blockquote>
      </div>
    </div>

    <div v-if="action">
      <div class="bs container d-flex align-items-center justify-content-end justify-content-lg-between">
        <h3 class="bs display-4 d-none d-lg-block">
          The cost of action<br/>
          can be calculated.<br/>
          The cost of nonaction cannot.
        </h3>
        <blockquote class="bs blockquote text-right">
            To shape risks into rewards <br/>
            we are ready for you to <br/>
            <mark>get into&nbsp;<span class="bs d-inline d-sm-none"><br/>&nbsp;</span>action.</mark>
        </blockquote>
      </div>
    </div>

    <div v-if="care">
      <div class="bs container d-flex align-items-center justify-content-end justify-content-lg-between">
        <h3 class="bs display-4 d-none d-lg-block">
          We care about you<br/>
          and your vision.
        </h3>
        <blockquote class="bs blockquote text-right">
            Let's focus on where <br/>your <mark>growth</mark> is <br/>and how to get there.
        </blockquote>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageQuote",
  props: {
    winner: Boolean,
    action: Boolean,
    care: Boolean
  }
};
</script>

<style lang="scss">
.cc.pagequote {
  background-color: $gray-200;

  background-image: url(../assets/img/stickerbomb-bg1-1920x720.jpg);
  box-shadow: inset 0 0 0 50vw rgba($white,0.9);

  max-height: 400px;

  .container {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h3, blockquote {
    color: $gray-600;
  }

  blockquote {
    font-size: 1.2em;
    line-height: 1.2em;
  }

  mark {
    margin-top: 10px;
    padding: 0px 8px;
    line-height: 1em;
  }
}

@include media-breakpoint-down(md) {
  .cc.pagequote {
    .container {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

html.dark-mode {
  .cc.pagequote {
    background-color: $gray-900;
    box-shadow: inset 0 0 0 50vw rgba($black,0.9);
    max-height: 400px;

    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    h3, blockquote {
      color: $light;
    }

    mark {
      background-color: $black;
    }
  }
}
</style>
