<template>
  <blockquote class="cc pagesubtitle bs blockquote">
    {{ text }}
  </blockquote>
</template>

<script>
export default {
  name: "PageSubtitle",
  props: {
    text: String
  }
};
</script>

<style lang="scss">
.cc.pagesubtitle {
  color: $gray-600;
  font-size: 1em;
  font-weight: 300;
}

@include media-breakpoint-down(md) {
  .cc.pagesubtitle {
    text-align: right;
    font-size: 0.8em;
  }
}

html.dark-mode {
  .cc.pagesubtitle {
    color: $gray-500;
  }
}
</style>
