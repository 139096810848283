import { createRouter, createWebHistory } from "vue-router";

import PortfolioPage from "@/views/PortfolioPage.vue";
import DigitalProductAdvisoryPage from "@/views/DigitalProductAdvisoryPage.vue";
import ModernManagementPage from "@/views/ModernManagementPage.vue";
import ExecutiveCoachingPage from "@/views/ExecutiveCoachingPage.vue";
import LeadershipCoachingPage from "@/views/LeadershipCoachingPage.vue";
import AgileMentoringPage from "@/views/AgileMentoringPage.vue";
import ProBonoMentoringPage from "@/views/ProBonoMentoringPage.vue";
import AboutPage from "@/views/AboutPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import ReleasePage from "@/views/ReleasePage.vue";
import BackstagePage from "@/views/BackstagePage.vue";
import LegalNotesPage from "@/views/LegalNotesPage.vue";

import window from "window-or-global";

const history = createWebHistory();

const routes = [
  {
    path: "/",
    name: "home",
    component: PortfolioPage
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: PortfolioPage
  },
  {
    path: "/about",
    name: "about",
    component: AboutPage
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactPage
  },
  {
    path: "/legalnotes",
    name: "legalnotes",
    component: LegalNotesPage
  },
  {
    path: "/backstage",
    name: "backstage",
    component: BackstagePage
  },
  {
    path: "/digitalproductadvisory",
    name: "digitalproductadvisory",
    component: DigitalProductAdvisoryPage
  },
  {
    path: "/modernmanagement",
    name: "modernmanagement",
    component: ModernManagementPage
  },
  {
    path: "/executivecoaching",
    name: "executivecoaching",
    component: ExecutiveCoachingPage
  },
  {
    path: "/leadershipcoaching",
    name: "leadershipcoaching",
    component: LeadershipCoachingPage
  },
  {
    path: "/agilementoring",
    name: "agilementoring",
    component: AgileMentoringPage
  },
  {
    path: "/probonomentoring",
    name: "probonomentoring",
    component: ProBonoMentoringPage
  },
  {
    path: "/release/:version",
    name: "release",
    component: ReleasePage
  }
];

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) return savedPosition;

  window.scrollTo(0, 0);

  return { x: 0, y: 0 };
};

const router = createRouter({
  history,
  routes,
  scrollBehavior
});

export default router;
