<template>
  <section class="cc contactaction" aria-label="Callout">
    <div class="bs row d-flex align-items-center justify-content-between" v-if="!linkOnly">
      <div class="bs col-12 col-md-6 col-lg-7 col-xl-8">
        <h3 class="cc teaser">
          {{ teaser || "Interested in working with us?" }}
        </h3>
        <p class="cc subtext">
          {{ subtext || "Hit us up and we will rock and roll!" }}
        </p>
      </div>
      <div class="cc cta bs col-12 col-md-auto d-flex">
        <router-link :to="link" class="bs btn btn-primary flex-grow-1">
          <span class="cc caption">{{ cta || "Let's do it" }}</span> <fa icon="arrow-right"></fa>
        </router-link>
      </div>
    </div>
    <div class="cc cta bs d-flex align-items-center justify-content-md-end" v-if="linkOnly">
      <router-link :to="link" class="bs btn btn-primary flex-grow-1 flex-md-grow-0">
        <span class="cc caption">{{ cta || "Let's do it" }}</span> <fa icon="arrow-right"></fa>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactAction",
  props: {
    teaser: String,
    subtext: String,
    linkOnly: Boolean,
    link: {
      type: String,
      default: "contact"
    },
    cta: String
  }
};
</script>

<style lang="scss">
.cc.contactaction {
  .teaser {
    margin: 0px;
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: normal;
    font-style: italic;
  }

  .subtext {
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 40px;
    line-height: 1em;
    font-size: 0.8em;
    font-style: normal;
    color: $gray-600;
  }

  .cta {
    margin: 0px;

    .btn {
      padding: 10px 20px;
      margin: 0px;

      .caption {
        text-transform: uppercase;
        margin-right: 10px;
        font-weight: normal;
      }
    }

    .btn.btn-primary {
      border: 0px;
      border-top: 4px solid $primary;
      background-color: $white;

      * {
        color: $dark;
      }
    }

    .btn.btn-primary:hover {
      border-top-color: $dark;

      * {
        color: $dark;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cc.contactaction {
    .teaser {
      text-align: center;
      margin-bottom: 15px;
    }

    .subtext {
      display: none;
    }

    .cta {
      .btn {
        margin: 0px;
      }
    }
  }
}

html.dark-mode {
  .cc.contactaction {
    .cta {
      .btn.btn-primary {
        border-top: 4px solid $primary;
        background-color: $dark;

        * {
          color: $light;
        }
      }

      .btn.btn-primary:hover {
        border-top-color: $light;

        * {
          color: $white;
        }
      }
    }
  }
}
</style>
